import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const AddressEdit = ({ addresses, setAddresses, token, loadingStatus }) => {
  const [editIndex, setEditIndex] = useState(-1);
  const [editedAddress, setEditedAddress] = useState({});

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditedAddress({ ...addresses[index] });
  };

  const handleInputChange = (event, field) => {
    const updatedAddress = { ...editedAddress, [field]: event.target.value };
    setEditedAddress(updatedAddress);
  };

  const handleUpdateClick = (id) => {
    let config = {
      method: "PUT",
      url: `${process.env.REACT_APP_BACKEND_URL}/user/address/${id}`,
      headers: {
        Authorization: token,
      },
      data: editedAddress,
    };

    axios
      .request(config)
      .then((res) => {
        const index = addresses.findIndex((address) => address._id === id);

        if (index !== -1) {
          const updatedAddress = [...addresses];
          updatedAddress[index] = res.data;

          setAddresses(updatedAddress);
        }
        setEditIndex(-1);
      })
      .catch(() => {
        toast.error("Could not update address");
      });
  };

  return (
    <div className="myaccount-content">
      <h5>Billing Address</h5>
      {loadingStatus === "loading" && (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "40vh" }}
        >
          <img src="/assets/img/logo/Rolling-1s-200px.svg" />
        </div>
      )}
      {loadingStatus === "failed" && (
        <p className="saved-message">Failed to fetch saved addresses.</p>
      )}
      {loadingStatus === "succeeded" &&
        (!addresses.length ? (
          <p className="saved-message">You don't have any saved address.</p>
        ) : (
          <div className="row">
            {addresses?.map((address, index) => (
              <div className="col-md-4" key={index}>
                {index === editIndex ? (
                  <>
                    <input
                      type="text"
                      value={editedAddress.line1 || ""}
                      onChange={(e) => handleInputChange(e, "line1")}
                      className="form-control mb-2"
                      placeholder="Address Line 1"
                    />
                    <input
                      type="text"
                      value={editedAddress.line2 || ""}
                      onChange={(e) => handleInputChange(e, "line2")}
                      className="form-control mb-2"
                      placeholder="Address Line 2"
                    />
                    <input
                      type="text"
                      value={editedAddress.city || ""}
                      onChange={(e) => handleInputChange(e, "city")}
                      className="form-control mb-2"
                      placeholder="City"
                    />
                    <input
                      type="text"
                      value={editedAddress.state || ""}
                      onChange={(e) => handleInputChange(e, "state")}
                      className="form-control mb-2"
                      placeholder="State"
                    />
                    <input
                      type="text"
                      value={editedAddress.postalCode || ""}
                      onChange={(e) => handleInputChange(e, "postalCode")}
                      className="form-control mb-2"
                      placeholder="Postal Code"
                    />
                    <input
                      type="text"
                      value={editedAddress.country || ""}
                      onChange={(e) => handleInputChange(e, "country")}
                      className="form-control mb-2"
                      placeholder="Country"
                    />
                    <button
                      className="btn btn-sqr"
                      onClick={() => handleUpdateClick(address._id)}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <>
                    <address>
                      <p>
                        <strong>{address.fullName}</strong>
                      </p>
                      <p>
                        {address.line1}
                        {address.line2 && `, ${address.line2}`}
                        <br />
                        {address.city}, {address.state} <br />
                        {address.postalCode}, {address.country}
                      </p>
                    </address>
                    <button
                      className="btn btn-sqr"
                      onClick={() => {
                        handleEditClick(index);
                      }}
                    >
                      Edit Address
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default AddressEdit;
