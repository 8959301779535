import React, { useState } from "react";
import BillingDetails from "../components/Checkout/BillingDetail";
// import Coupon from "../components/Checkout/Coupon";
import OrderSummary from "../components/Checkout/OrderSummary";
import Breadcrumb from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { IconContext } from "react-icons";
import { BsFillCartXFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { clearCart } from "../redux/CartSlice";
import { toast } from "react-toastify";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items } = useSelector((state) => state.cart);
  const { token } = useSelector((state) => state.user);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [activeTab, setActiveTab] = useState("tab_one");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cashon");
  const [formData, setFormData] = useState({
    country: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [sdkReady, setSdkReady] = useState(true);

  useEffect(() => {
    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/config/getPaypalClient`
      );
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;

      script.onload = () => {
        setSdkReady(true);
      };

      document.body.appendChild(script);
    };

    if (!window.paypal) addPaypalScript();
    else setSdkReady(true);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/addresses`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setAddresses(res.data);
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);

  const validateAddress = () => {
    if (
      activeTab === "tab_one" &&
      (!formData.country ||
        !formData.line1 ||
        !formData.city ||
        !formData.postalCode)
    ) {
      toast("Please enter a valid address");
      return false;
    } else if (activeTab === "tab_two" && !selectedAddress) {
      toast("Please select an address");
      return false;
    }

    return true;
  };

  const handleFormSubmit = () => {
    let paymentMethod;
    switch (selectedPaymentMethod) {
      case "paypalmode":
        paymentMethod = "Paypal";
        break;

      default:
        paymentMethod = "Cash On Delivery";
        break;
    }

    const checkoutData = {
      paymentMethod,
    };
    if (activeTab === "tab_one") {
      checkoutData.shippingAddress = formData;
    } else {
      checkoutData.addressId = selectedAddress;
    }

    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}/order`,
      headers: {
        Authorization: token,
      },
      data: checkoutData,
    };

    axios
      .request(config)
      .then((res) => {
        dispatch(clearCart());
        navigate("/shop");
      })
      .catch((error) => {
        toast.error("Failed to place order");
      });
  };

  return (
    <>
      <Breadcrumb />
      {items?.length ? (
        <div className="checkout-page-wrapper section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <BillingDetails
                  formData={formData}
                  setFormData={setFormData}
                  addresses={addresses}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
              <div className="col-lg-6">
                <OrderSummary
                  items={items}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  validateAddress={validateAddress}
                  handleFormSubmit={handleFormSubmit}
                  sdkReady={sdkReady}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "80vh", maxWidth: "200px" }}
        >
          <IconContext.Provider value={{ color: "#C29958" }}>
            <BsFillCartXFill size={100} />
          </IconContext.Provider>
          <p>
            Please add item into cart from{" "}
            <Link to="/shop" style={{ color: "#C29958" }}>
              Shop
            </Link>{" "}
            to checkout.
          </p>
        </div>
      )}
    </>
  );
};

export default Checkout;
