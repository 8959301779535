import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  AiFillEdit,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";

const FilterOptionRow = ({ option, index, setFilterOptions }) => {
  const { token } = useSelector((state) => state.user);
  const [name, setName] = useState(option?.name);
  const [editMode, setEditMode] = useState(false);

  const deleteFilterOption = async () => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/filter/filterOptions/${option._id}`,
          { headers: { Authorization: token } }
        ),
        {
          pending: "Deleting Filter Option...",
          success: "Filter Option Deleted Successfully!",
          error: "Failed to Delete Filter Option.",
        }
      );

      setFilterOptions((options) =>
        options.filter((opt) => opt._id !== option._id)
      );
    } catch (error) {
      toast.error("Failed to Delete Filter Option");
    }
  };

  const updateFilterOption = async () => {
    try {
      const response = await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/filter/filterOptions/${option._id}`,
          { name: name },
          { headers: { Authorization: token } }
        ),
        {
          pending: "Updating Filter Option...",
          success: "Filter Option Updated Successfully!",
          error: "Failed to Update Filter Option.",
        }
      );

      setFilterOptions((options) =>
        options.map((opt) => (opt._id === option._id ? response.data : opt))
      );

      setEditMode(false);
    } catch (error) {
      toast.error("Failed to Update Filter Option");
    }
  };

  return (
    <>
      <tr>
        <td className="pro-thumbnail">{index + 1}</td>
        <td className="pro-title">
          {editMode ? (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <input
                type="text"
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
                className="form-control mb-2"
                placeholder="Name"
              />
              <AiOutlineCloseCircle
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditMode(false);
                  setName(option?.name);
                }}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <p style={{ marginTop: "10px" }}>{name}</p>
              <AiFillEdit
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditMode(true);
                }}
              />
            </div>
          )}
        </td>
        <td className="pro-action">
          {" "}
          <button
            onClick={() => {
              updateFilterOption(option?._id);
            }}
            className="btn btn-sqr"
            style={{ marginRight: "10px" }}
            disabled={option?.name === name}
          >
            Update
          </button>
          <button
            onClick={() => {
              deleteFilterOption(option?._id);
            }}
            className="btn btn-sqr"
          >
            Delete
          </button>
        </td>
      </tr>
    </>
  );
};

const FilterDetailModal = ({ showModal, closeModal, filter, setFilters }) => {
  const { token } = useSelector((state) => state.user);
  const [newOptionName, setNewOptionName] = useState("");
  const [addOptionActive, setAddOptionActive] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterName, setFilterName] = useState(filter?.name);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setFilterOptions(filter?.filterOptions);
    setFilterName(filter?.name);
  }, [filter]);

  const addFilterOption = async () => {
    try {
      const response = await toast.promise(
        axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/filter/${filter._id}/filterOptions`,
          { name: newOptionName },
          {
            headers: { Authorization: token },
          }
        ),
        {
          pending: "Adding Filter Option...",
          success: "Filter Option Added Successfully!",
          error: "Failed to Add Filter Option.",
        }
      );

      setFilterOptions(response.data.filterOptions);
      setAddOptionActive(false);
    } catch (error) {
      toast.error("Failed to add Filter Option");
    }
  };

  const updateFilterName = async () => {
    try {
      const response = await toast.promise(
        axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/filter/${filter._id}`,
          {
            name: filterName,
          },
          { headers: { Authorization: token } }
        ),
        {
          pending: "Updating Filter Name...",
          success: "Filter Name Updated Successfully!",
          error: "Failed to Update Filter Name.",
        }
      );

      setFilters((currFilters) =>
        currFilters.map((fil) => (fil._id === filter._id ? response.data : fil))
      );

      setEditMode(false);
    } catch (error) {
      toast.error("Error updating filter");
    }
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="orderDetailModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <div className="d-flex justify-content-center align-items-center gap-2">
                {editMode ? (
                  <>
                    <input
                      type="text"
                      value={filterName || ""}
                      onChange={(e) => setFilterName(e.target.value)}
                      className="form-control mb-2"
                      placeholder="Name"
                    />
                    <AiOutlineCloseCircle
                      size={30}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditMode(false);
                        setFilterName(filter?.name);
                      }}
                    />
                    <AiOutlineCheckCircle
                      size={30}
                      style={{ cursor: "pointer" }}
                      onClick={updateFilterName}
                    />
                  </>
                ) : (
                  <>
                    <h5 className="modal-title">{filterName}</h5>
                    <AiFillEdit
                      style={{ marginTop: "4px", cursor: "pointer" }}
                      onClick={() => {
                        setEditMode(true);
                      }}
                    />
                  </>
                )}
              </div>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            {filter && (
              <div className="modal-body">
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="pro-thumbnail">S. No.</th>
                        <th className="pro-title">Option Name</th>
                        <th className="pro-price">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterOptions?.map((option, index) => {
                        return (
                          <FilterOptionRow
                            key={option?._id}
                            option={option}
                            index={index}
                            setFilterOptions={setFilterOptions}
                          />
                        );
                      })}
                      {addOptionActive && (
                        <tr>
                          <td className="pro-thumbnail">
                            {filter?.filterOptions?.length + 1}
                          </td>
                          <td className="pro-title">
                            <input
                              type="text"
                              value={newOptionName || ""}
                              onChange={(e) => setNewOptionName(e.target.value)}
                              className="form-control mb-2"
                              placeholder="Name"
                            />
                          </td>
                          <td className="pro-action">
                            {" "}
                            <button
                              onClick={addFilterOption}
                              className="btn btn-sqr"
                              style={{ marginRight: "10px" }}
                              disabled={!newOptionName}
                            >
                              Add
                            </button>
                            <button
                              onClick={() => {
                                setAddOptionActive(false);
                              }}
                              className="btn btn-sqr"
                              style={{ marginRight: "10px" }}
                            >
                              Cancel
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div
              className="modal-footer"
              style={{ textTransform: "capitalize" }}
            >
              <button
                className="btn btn-sqr"
                onClick={() => {
                  setAddOptionActive(true);
                }}
              >
                Add Option
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NewFilterModal = ({ showModal, closeModal, fetchAllFilters }) => {
  const { token } = useSelector((state) => state.user);
  const [filterName, setFilterName] = useState("");
  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState("");

  const addFilter = async () => {
    try {
      const filterOptions = options.map((option) => {
        return { name: option.trim() };
      });

      await toast.promise(
        axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/filter`,
          { name: filterName.trim(), filterOptions },
          {
            headers: { Authorization: token },
          }
        ),
        {
          pending: "Adding Filter...",
          success: "Filter Added Successfully!",
          error: "Failed to Add Filter.",
        }
      );

      fetchAllFilters();
      setFilterName("");
      setOptions([]);
      closeModal();
    } catch (error) {
      toast.error("Failed to add Filter");
    }
  };

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="add-filter-modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="mt-3 ms-2">New Filter</h5>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <table className="table ">
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }}>Title</td>
                    <td>
                      <input
                        type="text"
                        name="name"
                        value={filterName || ""}
                        onChange={(e) => setFilterName(e.target.value)}
                        className="form-control mb-2"
                        placeholder="Name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Options</td>
                    <td>
                      <div className="d-flex align-items-center gap-2 pb-2 flex-wrap">
                        {options?.map((option, index) => (
                          <span
                            key={option + index}
                            className="tags"
                            onClick={() => {
                              setOptions((currData) => {
                                let updatedData = [...currData];
                                updatedData.splice(index, 1);
                                return updatedData;
                              });
                            }}
                          >
                            {option}
                          </span>
                        ))}
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setOptions((currData) => {
                            let updatedData = [...currData];
                            if (newOption) updatedData.push(newOption.trim());
                            setNewOption("");
                            return updatedData;
                          });
                        }}
                      >
                        <input
                          type="text"
                          value={newOption || ""}
                          onChange={(e) => setNewOption(e.target.value)}
                          className="form-control mb-2"
                          placeholder="Add new Option"
                        />
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="modal-footer"
              style={{ textTransform: "capitalize" }}
            >
              <button
                className="btn btn-sqr"
                onClick={addFilter}
                disabled={!filterName || !options.length}
              >
                Add Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProductFilter = () => {
  const { token } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState("loading");
  const [filter, setFilter] = useState({});

  const openModal = (filterDetail) => {
    setFilter(filterDetail);
    setShowModal(true);
  };

  const closeModal = () => {
    setFilter(null);
    setShowModal(false);
  };

  const fetchAllFilters = () => {
    setLoadingStatus("loading");
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/filter`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setFilters(response.data);
        setLoadingStatus("succeeded");
      })
      .catch((error) => {
        toast.error("Failed to fetch filters");
        setLoadingStatus("failed");
      });
  };

  const fetchFilter = (filterId) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/filter/${filterId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        openModal(res.data);
      })
      .catch((err) => toast.error("Error fetching filter"));
  };

  const deleteFilter = async (filterId) => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/filter/${filterId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Filter...",
          success: "Filter Deleted Successfully!",
          error: "Failed to Delete Filter.",
        }
      );

      fetchAllFilters();
    } catch (error) {
      toast.error("Error deleting filter");
    }
  };

  useEffect(() => {
    fetchAllFilters();
  }, []);

  return (
    <>
      <FilterDetailModal
        showModal={showModal}
        closeModal={closeModal}
        filter={filter}
        setFilter={setFilter}
        setFilters={setFilters}
      />
      <NewFilterModal
        showModal={addNewModal}
        closeModal={() => {
          setAddNewModal(false);
        }}
        fetchAllFilters={fetchAllFilters}
      />

      <div className="myaccount-content">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 style={{ border: "none", margin: 0 }}>Product Filters</h5>
          <button
            onClick={() => {
              setAddNewModal(true);
            }}
            className="btn btn-sqr "
          >
            Add new
          </button>
        </div>
        {loadingStatus === "loading" && (
          <div
            className="d-flex flex-column justify-content-center align-items-center gap-4"
            style={{ height: "40vh" }}
          >
            <img src="/assets/img/logo/Rolling-1s-200px.svg" />
          </div>
        )}
        {loadingStatus === "failed" && (
          <p className="saved-message">Failed to fetch Filters.</p>
        )}
        {loadingStatus === "succeeded" && (
          <div className="myaccount-table table-responsive text-center">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Sr. No.</th>
                  <th>Filter Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filters?.map((filter, index) => {
                  return (
                    <tr key={filter?._id}>
                      <td>{index + 1}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {filter?.name}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        <Link
                          onClick={() => {
                            fetchFilter(filter?._id);
                          }}
                          className="btn btn-sqr"
                          style={{ marginRight: "10px" }}
                        >
                          View
                        </Link>
                        <Link
                          onClick={() => {
                            deleteFilter(filter?._id);
                          }}
                          className="btn btn-sqr"
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductFilter;
