import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../redux/UserSlice";
import Breadcrumb from "../components/Breadcrumb";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    uid: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setLoginData({ ...loginData, [name]: inputValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loginData.uid || !loginData.password) {
      toast.error("Please enter your username and password");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/login`,
          {
            uid: loginData.uid,
            password: loginData.password,
          }
        );

        if (response.data.token) {
          dispatch(
            login({
              auth: true,
              name: response.data.fullName,
              role: response.data.role,
              token: response.data.token,
            })
          );
          if (response.data.role === "admin") {
            navigate("/my-account");
          } else {
            navigate("/shop");
          }
          toast.success("Login successful!");
        } else {
          toast.error("Invalid credentials. Please try again.");
        }
      } catch (error) {
        toast.error("Invalid credentials. Please try again.");
      }
    }
  };

  return (
    <>
      <Breadcrumb />

      <div className="login-register-wrapper section-padding">
        <div className="container">
          <div className="member-area-from-wrap d-flex justify-content-center">
            <div className="row">
              <div className="col-lg-6">
                <div className="login-reg-form-wrap">
                  <h5>Sign In</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="single-input-item">
                      <input
                        name="uid"
                        placeholder="Email or Mobile Number"
                        value={loginData.uid}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div
                      className="single-input-item d-flex align-items-center justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter your Password"
                        value={loginData.password}
                        onChange={handleInputChange}
                        required
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: 0,
                          padding: "0 10px",
                          color: "#c29958",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <AiOutlineEye size={25} />
                        ) : (
                          <AiOutlineEyeInvisible size={25} />
                        )}
                      </button>
                    </div>
                    <div className="single-input-item">
                      <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                        <Link to="/forgotPassword" className="forget-pwd">
                          Forget Password?
                        </Link>
                      </div>
                    </div>
                    <div className="single-input-item">
                      <button type="submit" className="btn btn-sqr">
                        Login
                      </button>
                    </div>
                    <div
                      className="single-input-item text-center"
                      style={{ fontSize: "15px" }}
                    >
                      Don't have Account?{" "}
                      <Link style={{ color: "#c29958" }} to="/register">
                        Sign Up
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
