import axios from "axios";
import React, { useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [forgotData, setForgotData] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setForgotData({ ...forgotData, [name]: inputValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/forgot-password`,
        {
          email: forgotData.email,
          password: forgotData.password,
        }
      );

      if (response.data) {
        toast.success(
          `Password reset email sent successfully to ${forgotData.email}`
        );
      } else {
        toast.error("Failed. Please try again.");
      }
    } catch (error) {
      toast.error("Failed. Please try again.");
    }
  };

  return (
    <>
      <Breadcrumb />
      <div className="login-register-wrapper section-padding">
        <div className="container">
          <div className="member-area-from-wrap d-flex justify-content-center">
            <div className="row">
              <div className="col-lg-6">
                <div className="login-reg-form-wrap">
                  <h5>Forgot Password</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="single-input-item">
                      <input
                        type="email"
                        name="email"
                        placeholder="Please enter your email address"
                        value={forgotData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="single-input-item">
                      <button type="submit" className="btn btn-sqr">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
