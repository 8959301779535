import React, { useState } from "react";

const ProductDetailsReviews = ({ description, variants }) => {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   review: "",
  //   rating: 5, // Default rating
  // });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // onSubmit(formData); // Pass form data to the parent component for submission
  // };

  const [activeTab, setActiveTab] = useState("tab_one");

  return (
    <div className="product-details-reviews section-padding pb-0">
      <div className="row">
        <div className="col-lg-12">
          <div className="product-review-info">
            <ul className="nav review-tab">
              <li>
                <a
                  className={activeTab === "tab_one" ? `active` : ""}
                  data-bs-toggle="tab"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab("tab_one");
                  }}
                >
                  description
                </a>
              </li>
              <li>
                <a
                  className={activeTab === "tab_two" ? `active` : ""}
                  data-bs-toggle="tab"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab("tab_two");
                  }}
                >
                  information
                </a>
              </li>
              {/* <li>
                <a
                  className={activeTab === "tab_three" ? `active` : ""}
                  data-bs-toggle="tab"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab("tab_three");
                  }}
                >
                  reviews ({reviews.length})
                </a>
              </li> */}
            </ul>
            <div className="tab-content reviews-tab">
              <div
                className={`tab-pane fade   ${
                  activeTab === "tab_one" ? "show active" : ""
                } `}
                id="tab_one"
              >
                <div className="tab-one">
                  <p>{description}</p>
                </div>
              </div>
              <div
                className={`tab-pane fade   ${
                  activeTab === "tab_two" ? "show active" : ""
                } `}
                id="tab_two"
              >
                <table className="table table-bordered">
                  <tbody>
                    {variants?.map((variant, index) => {
                      return (
                        <tr key={index}>
                          <td>{variant.type}</td>
                          <td>
                            {variant?.options?.map(
                              (option, index) =>
                                `${option?.name}${
                                  index != variant?.options.length - 1
                                    ? ", "
                                    : ""
                                }`
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div
                className={`tab-pane fade   ${
                  activeTab === "tab_three" ? "show active" : ""
                } `}
                id="tab_three"
              >
                {/* Reviews */}
                {/* <form onSubmit={handleSubmit} className="review-form">
                  <h5>
                    1 review for <span>Chaz Kangeroo</span>
                  </h5>
                  <div className="total-reviews">
                    {reviews.map((review, index) => (
                      <>
                        <div className="rev-avatar" key={index}>
                          <img
                            src={review.avatar}
                            alt={`Reviewer ${index + 1}`}
                          />
                        </div>
                        <div className="review-box">
                          <div className="ratings">
                            {[...Array(review.rating)].map((_, i) => (
                              <span className="good" key={i}>
                                <i className="fa fa-star" />
                              </span>
                            ))}
                            {[...Array(5 - review.rating)].map((_, i) => (
                              <span key={i}>
                                <i className="fa fa-star" />
                              </span>
                            ))}
                          </div>
                          <div className="post-author">
                            <p>
                              <span>
                                {review.name} - {review.date}
                              </span>
                            </p>
                          </div>
                          <p>{review.comment}</p>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="form-group row">
                    <div className="col">
                      <label className="col-form-label">
                        <span className="text-danger">*</span>Your Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Your Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Your Review
                      </label>
                      <textarea
                        className="form-control"
                        name="review"
                        value={formData.review}
                        onChange={handleInputChange}
                        required
                      />
                      <div className="help-block pt-10">
                        <span className="text-danger">Note:</span> HTML is not
                        translated!
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Rating
                      </label>
                      &nbsp;&nbsp;&nbsp; Bad&nbsp;
                      {[1, 2, 3, 4, 5].map((value) => (
                        <React.Fragment key={value}>
                          <input
                            type="radio"
                            value={value}
                            name="rating"
                            checked={formData.rating === value}
                            onChange={handleInputChange}
                          />
                          &nbsp;
                        </React.Fragment>
                      ))}
                      Good
                    </div>
                  </div>

                  <div className="buttons">
                    <button className="btn btn-sqr" type="submit">
                      Continue
                    </button>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsReviews;
