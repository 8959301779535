import axios from "axios";
import { useState, useRef } from "react";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductBySlug } from "../../../../redux/ProductSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VariantOptionRow = ({ option, slug, deleteOption, updateNewOption }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [updatedOption, setUpdatedOption] = useState({});
  const [nameEditMode, setNameEditMode] = useState(false);
  const [rateEditMode, setRateEditMode] = useState(false);

  useState(() => {
    setUpdatedOption(option);
  }, [option]);

  const optionChangeHandler = (e) => {
    setUpdatedOption((currOption) => {
      let changedOption;
      if (e.target.type === "checkbox") {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.checked,
        };
      } else {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.value,
        };
      }
      return changedOption;
    });
  };

  const updateVariantOption = async () => {
    const { _id, __v, ...data } = updatedOption;
    try {
      await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/product/variant-option/${option?._id}`,
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Updating Variant Option...",
          success: "Variant Option Updated Successfully!",
          error: "Failed to Update Variant Option.",
        }
      );

      dispatch(fetchProductBySlug(slug));
      setNameEditMode(false);
      setRateEditMode(false);
    } catch (error) {
      toast.error("Failed to update variant option");
    }
  };

  const deleteVariantOption = async () => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/product/variant-option/${option?._id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Variant Option...",
          success: "Variant Option Deleted Successfully!",
          error: "Failed to Delete Variant Option.",
        }
      );

      dispatch(fetchProductBySlug(slug));
    } catch (error) {
      toast.error("Failed to Delete Variant Option.");
    }
  };

  const updateNewVariantOption = () => {
    updateNewOption(updatedOption);
    setNameEditMode(false);
    setRateEditMode(false);
  };

  return (
    <tr key={updatedOption?._id}>
      <td style={{ width: "30%" }}>
        <div className="d-flex gap-2 align-items-center">
          {nameEditMode ? (
            <>
              <input
                type="text"
                name="name"
                value={updatedOption?.name || ""}
                onChange={optionChangeHandler}
                className="form-control mb-2"
                placeholder="Name"
              />
            </>
          ) : (
            <>
              <p style={{ marginTop: "10px" }}>{updatedOption?.name}</p>
              <AiFillEdit
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNameEditMode(true);
                }}
              />
            </>
          )}
        </div>
      </td>
      <td>
        <div className="d-flex gap-2 align-items-center">
          {rateEditMode ? (
            <>
              <input
                type="number"
                name="incRate"
                value={updatedOption?.incRate || ""}
                onChange={optionChangeHandler}
                className="form-control mb-2"
                placeholder="Inc Rate"
              />
            </>
          ) : (
            <>
              <p style={{ marginTop: "10px" }}>{updatedOption?.incRate}</p>
              <AiFillEdit
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setRateEditMode(true);
                }}
              />
            </>
          )}
        </div>
      </td>
      <td>
        <div className="custom-control custom-checkbox d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="custom-control-input"
            name="isActive"
            id={updatedOption?._id + 1}
            checked={updatedOption.isActive}
            onChange={optionChangeHandler}
          />
          <label
            className="custom-control-label"
            for={updatedOption?._id + 1}
          ></label>
        </div>
      </td>
      <td>
        <div className="custom-control custom-checkbox ">
          <input
            type="checkbox"
            className="custom-control-input"
            name="inStock"
            id={updatedOption?._id + 2}
            checked={updatedOption.inStock}
            onChange={optionChangeHandler}
          />
          <label
            className="custom-control-label"
            for={updatedOption?._id + 2}
          ></label>
        </div>
      </td>
      <td className="d-flex justify-content-center align-items-center gap-2">
        {!option?._id?.includes("new") ? (
          <>
            <button
              onClick={updateVariantOption}
              className="btn btn-sqr"
              disabled={
                JSON.stringify(updatedOption) === JSON.stringify(option)
              }
            >
              Update
            </button>
            <button onClick={deleteVariantOption} className="btn btn-sqr">
              Delete
            </button>
          </>
        ) : (
          <>
            <button
              onClick={updateNewVariantOption}
              className="btn btn-sqr"
              disabled={
                JSON.stringify(updatedOption) === JSON.stringify(option)
              }
            >
              Update
            </button>
            <button onClick={deleteOption} className="btn btn-sqr">
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

const VariantRow = ({ variant, productId, slug }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [nameEditMode, setNameEditMode] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [mouseIn, setMouseIn] = useState(false);
  const [newOptionData, setNewOptionData] = useState({
    incRate: "",
    name: "",
    isActive: true,
    inStock: true,
  });
  const [showNewOption, setShowNewOption] = useState(false);

  useEffect(() => {
    setVariantName(variant?.type);
  }, [variant]);

  const updateVariantName = async () => {
    try {
      await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/product/${productId}/variants/${variant._id}`,
          { type: variantName },
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Updating Variant Name...",
          success: "Variant Name Updated Successfully!",
          error: "Failed to Update Variant Name.",
        }
      );
      setNameEditMode(false);
    } catch (error) {
      toast.error("Failed to Update Variant Name");
    }
  };

  const createVariantOption = async () => {
    try {
      await toast.promise(
        axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/product/variant-option/${variant?._id}`,
          newOptionData,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Creating Variant Option...",
          success: "Variant Option Created Successfully!",
          error: "Failed to Create Variant Option.",
        }
      );
      dispatch(fetchProductBySlug(slug));
    } catch (error) {
      toast.error("Failed to Create Variant Option.");
    }
  };

  const deleteVariantFromProduct = async () => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/product/${productId}/variants/${variant._id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Variant...",
          success: "Variant Deleted Successfully!",
          error: "Failed to Delete Variant.",
        }
      );
      dispatch(fetchProductBySlug(slug));
    } catch (error) {
      toast.error("Failed to Delete Variant");
    }
  };

  const newOptionChangeHandler = (e) => {
    setNewOptionData((currOption) => {
      let changedOption;
      if (e.target.type === "checkbox") {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.checked,
        };
      } else {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.value,
        };
      }
      return changedOption;
    });
  };

  return (
    <tr
      key={variant._id}
      onMouseEnter={() => {
        setMouseIn(true);
      }}
      onMouseLeave={() => {
        setMouseIn(false);
      }}
    >
      <td
        className={`add-row ${mouseIn ? "show" : ""}`}
        style={{ border: "none" }}
        onClick={deleteVariantFromProduct}
        rowSpan={2}
      >
        <AiFillDelete size={20} />
      </td>
      <td style={{ width: "20%" }}>
        <div className="d-flex  align-items-center gap-2">
          {nameEditMode ? (
            <>
              <input
                type="text"
                value={variantName || ""}
                onChange={(e) => setVariantName(e.target.value)}
                className="form-control mb-2"
                placeholder="Name"
              />
              <AiOutlineCloseCircle
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNameEditMode(false);
                  setVariantName(variant?.type);
                }}
              />
              <AiOutlineCheckCircle
                size={25}
                style={{ cursor: "pointer" }}
                onClick={updateVariantName}
              />
            </>
          ) : (
            <>
              <p style={{ marginTop: "10px" }}>{variantName}</p>
              <AiFillEdit
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNameEditMode(true);
                }}
              />
            </>
          )}
        </div>
      </td>
      <td>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Option Name</th>
              <th>Inc Rate</th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Active
              </th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Stock
              </th>
              <th
                style={{
                  width: "25%",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {variant?.options?.map((option) => {
              return (
                <VariantOptionRow
                  option={option}
                  slug={slug}
                  key={option?._id}
                />
              );
            })}
            {showNewOption && (
              <tr key={newOptionData?._id}>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={newOptionData?.name || ""}
                    onChange={newOptionChangeHandler}
                    className="form-control mb-2"
                    placeholder="Name"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="incRate"
                    value={newOptionData?.incRate || ""}
                    onChange={newOptionChangeHandler}
                    className="form-control mb-2"
                    placeholder="Inc Rate"
                  />
                </td>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="isActive"
                      className="custom-control-input"
                      id={variant?._id + "new" + 1}
                      checked={newOptionData?.isActive}
                      onChange={newOptionChangeHandler}
                    />
                    <label
                      className="custom-control-label"
                      for={variant?._id + "new" + 1}
                    ></label>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="inStock"
                      className="custom-control-input"
                      id={variant?._id + "new" + 2}
                      checked={newOptionData?.inStock}
                      onChange={newOptionChangeHandler}
                    />
                    <label
                      className="custom-control-label"
                      for={variant?._id + "new" + 2}
                    ></label>
                  </div>
                </td>
                <td className="d-flex justify-content-center align-items-center gap-2">
                  <button
                    onClick={createVariantOption}
                    className="btn btn-sqr"
                    disabled={!newOptionData?.name}
                  >
                    Add
                  </button>
                  <button
                    onClick={() => {
                      setShowNewOption(false);
                    }}
                    className="btn btn-sqr"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!showNewOption && (
          <div
            className={`add-row ${mouseIn ? "show" : ""}`}
            onClick={() => {
              setShowNewOption(true);
            }}
          >
            <AiOutlinePlusCircle size={20} />
            <AiOutlinePlusCircle size={20} />
          </div>
        )}
      </td>
    </tr>
  );
};

const NewVariantRow = ({ productId, slug, setShowNewVariant }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [variantName, setVariantName] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);
  const [mouseIn, setMouseIn] = useState(false);
  const [newOptionData, setNewOptionData] = useState({
    incRate: "",
    name: "",
    isActive: true,
    inStock: true,
  });

  const newOptionChangeHandler = (e) => {
    setNewOptionData((currOption) => {
      let changedOption;
      if (e.target.type === "checkbox") {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.checked,
        };
      } else {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.value,
        };
      }
      return changedOption;
    });
  };

  const addVariantToProduct = async () => {
    if (!variantName || !variantOptions.length) return;

    const options = variantOptions.map((option) => {
      const { _id, ...data } = option;
      return data;
    });

    const variantData = { type: variantName, options };

    try {
      const response = await toast.promise(
        axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/product/${productId}/variants`,
          variantData,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Adding Variant...",
          success: "Variant Added Successfully!",
          error: "Failed to Add Variant.",
        }
      );

      const newVariant = response?.data;

      if (newVariant) {
        dispatch(fetchProductBySlug(slug));
        setShowNewVariant(false);
      } else {
        throw new Error("Failed to add variant.");
      }
    } catch (error) {
      toast.error("Failed to add variant.");
    }
  };

  const deleteVariantOption = (ind) => {
    setVariantOptions((currOption) => {
      let updatedData = [...currOption];
      updatedData.splice(ind, 1);
      return updatedData;
    });
  };

  const updateVariantOption = (updatedOption, ind) => {
    setVariantOptions((currOption) => {
      let updatedData = currOption.map((option, i) => {
        if (i !== ind) {
          return option;
        }
        return updatedOption;
      });

      return updatedData;
    });
  };

  return (
    <tr
      onMouseEnter={() => {
        setMouseIn(true);
      }}
      onMouseLeave={() => {
        setMouseIn(false);
      }}
    >
      <td
        className={`add-row ${mouseIn ? "show" : ""}`}
        style={{ border: "none" }}
        onClick={addVariantToProduct}
        rowSpan={2}
      >
        <AiOutlineCheck size={20} />
      </td>
      <td
        className={`add-row ${mouseIn ? "show" : ""}`}
        style={{ border: "none" }}
        onClick={() => {
          setShowNewVariant(false);
        }}
        rowSpan={2}
      >
        <AiFillDelete size={20} />
      </td>
      <td style={{ width: "20%" }}>
        <div className="d-flex align-items-center gap-2">
          <>
            <input
              type="text"
              value={variantName || ""}
              onChange={(e) => setVariantName(e.target.value)}
              className="form-control mb-2"
              placeholder="Name"
            />
          </>
        </div>
      </td>
      <td>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Option Name</th>
              <th>Inc Rate</th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Active
              </th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Stock
              </th>
              <th
                style={{
                  width: "25%",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {variantOptions?.map((option, ind) => {
              return (
                <VariantOptionRow
                  option={option}
                  key={option?.key}
                  deleteOption={() => {
                    deleteVariantOption(ind);
                  }}
                  updateNewOption={(updatedOption) => {
                    updateVariantOption(updatedOption, ind);
                  }}
                />
              );
            })}
            <tr key={newOptionData?._id}>
              <td>
                <input
                  type="text"
                  name="name"
                  value={newOptionData?.name || ""}
                  onChange={newOptionChangeHandler}
                  className="form-control mb-2"
                  placeholder="Name"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="incRate"
                  value={newOptionData?.incRate || ""}
                  onChange={newOptionChangeHandler}
                  className="form-control mb-2"
                  placeholder="Inc Rate"
                />
              </td>
              <td>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="isActive"
                    className="custom-control-input"
                    id={"newOpt" + 1}
                    checked={newOptionData?.isActive}
                    onChange={newOptionChangeHandler}
                  />
                  <label
                    className="custom-control-label"
                    for={"newOpt" + 1}
                  ></label>
                </div>
              </td>
              <td>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="inStock"
                    className="custom-control-input"
                    id={"newOpt" + 2}
                    checked={newOptionData?.inStock}
                    onChange={newOptionChangeHandler}
                  />
                  <label
                    className="custom-control-label"
                    for={"newOpt" + 2}
                  ></label>
                </div>
              </td>
              <td className="d-flex justify-content-center align-items-center gap-2">
                <button
                  onClick={() => {
                    setNewOptionData((currData) => {
                      setVariantOptions((currOptions) => [
                        ...currOptions,
                        {
                          ...currData,
                          _id: "new" + variantOptions.length,
                        },
                      ]);
                      return currData;
                    });
                    setNewOptionData({
                      incRate: "",
                      name: "",
                      isActive: true,
                      inStock: true,
                    });
                  }}
                  className="btn btn-sqr"
                  disabled={!newOptionData?.name}
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const ImageCard = ({ url, deleteHandler, backendUrl }) => {
  const [mouseIn, setMouseIn] = useState(false);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (backendUrl) {
      setImageURL(encodeURI(backendUrl + "/" + url?.replaceAll("\\", "/")));
    } else {
      setImageURL(url);
    }
  }, [url, backendUrl]);

  return (
    <>
      {imageURL && (
        <div
          className="uploaded-images"
          style={{
            backgroundImage: `url(${imageURL})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onMouseEnter={() => {
            setMouseIn(true);
          }}
          onMouseLeave={() => {
            setMouseIn(false);
          }}
        >
          {mouseIn && (
            <div className="overlay" onClick={deleteHandler}>
              <AiFillDelete size={20} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const VideoCard = ({ url, deleteHandler, backendUrl }) => {
  const [mouseIn, setMouseIn] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    if (backendUrl) {
      setVideoURL(encodeURI(backendUrl + "/" + url?.replaceAll("\\", "/")));
    } else {
      setVideoURL(url);
    }
  }, [url, backendUrl]);

  return (
    <div
      className="uploaded-videos col-3"
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}
    >
      {videoURL && (
        <video className="video-preview" controls>
          <source src={videoURL} type="video/mp4" />
          {/* <source src={videoURL} type="video/webm" />
          <source src={videoURL} type="video/ogg" /> */}
          Your browser does not support the video tag.
        </video>
      )}
      {mouseIn && (
        <div className="overlay" onClick={deleteHandler}>
          <AiFillDelete size={20} />
        </div>
      )}
    </div>
  );
};

const ProductDetailsTabs = ({ product, filters }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const imgRef = useRef();
  // const vdoRef = useRef();
  const [activeTab, setActiveTab] = useState("tab_one");
  const [updateDetailsData, setUpdateDetailsData] = useState({});
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showNewVariant, setShowNewVariant] = useState(false);
  const [images, setImages] = useState([]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  // const [videos, setVideos] = useState([]);
  // const [uploadedVideoUrls, setUploadedVideoUrls] = useState([]);

  useEffect(() => {
    setUpdateDetailsData({
      title: product?.title,
      description: product?.description,
      basePrice: product?.basePrice,
      featured: product?.featured,
      bestSeller: product?.bestSeller,
      onSale: product?.onSale,
    });
    setTags(product?.tags);
    setSelectedFilters(product?.filterOptions);
  }, [product]);

  const updateDetailsHandler = (e) => {
    setUpdateDetailsData({
      ...updateDetailsData,
      [e.target.name]: e.target.value,
    });
  };

  const updateProductDetails = async () => {
    try {
      const updatedProduct = await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/product/${product?._id}`,
          { ...updateDetailsData, tags },
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Updating Product Details...",
          success: "Product Details Updated Successfully!",
          error: "Failed to Update Product Details.",
        }
      );

      dispatch(fetchProductBySlug(updatedProduct?.data?.slug));
    } catch (error) {
      toast.error("Failed to update Product Details.");
    }
  };

  const updateProductFilterOptions = async () => {
    try {
      await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/product/${product?._id}/filterOptions`,
          { filterOptionIds: selectedFilters },
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Updating Product Filter Options...",
          success: "Product Filter Options Updated Successfully!",
          error: "Failed to Update Product Filter Options.",
        }
      );
    } catch (error) {
      toast.error("Failed to Update Product Filter Options.");
    }
  };

  const addImagesToProduct = async (e) => {
    e.preventDefault();
    try {
      await toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            const formData = new FormData();

            [...images]?.forEach((image) => {
              formData.append(`images`, image);
            });

            const res = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/product/${product?._id}/images`,
              formData,
              {
                headers: {
                  Authorization: token,
                },
              }
            );

            if (res.status === 200) {
              resolve("Images uploaded successfully!");
            } else {
              reject("Failed to upload images.");
            }
          } catch (error) {
            reject(error);
          }
        }),
        {
          pending: "Uploading Images...",
          success: "Images Uploaded Successfully!",
          error: "Failed to Upload Images.",
        }
      );

      setImages([]);
      setUploadedImageUrls([]);
      dispatch(fetchProductBySlug(product.slug));
    } catch (error) {
      toast.error("Failed to upload images.");
    }
  };

  // const addVideosToProduct = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await toast.promise(
  //       new Promise(async (resolve, reject) => {
  //         try {
  //           const formData = new FormData();

  //           [...videos].forEach((video) => {
  //             formData.append(`videos`, video);
  //           });

  //           const res = await axios.post(
  //             `${process.env.REACT_APP_BACKEND_URL}/product/${product?._id}/videos`,
  //             formData,
  //             {
  //               headers: {
  //                 Authorization: token,
  //               },
  //             }
  //           );

  //           if (res.status === 200) {
  //             resolve("Videos uploaded successfully!");
  //           } else {
  //             reject("Failed to upload videos.");
  //           }
  //         } catch (error) {
  //           reject(error);
  //         }
  //       }),
  //       {
  //         pending: "Uploading Videos...",
  //         success: "Videos Uploaded Successfully!",
  //         error: "Failed to Upload Videos.",
  //       }
  //     );

  //     setVideos([]);
  //     setUploadedVideoUrls([]);
  //     dispatch(fetchProductBySlug(product.slug));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteImageFromProduct = async (index) => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/product/${product?._id}/images/${index}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Image...",
          success: "Image Deleted Successfully!",
          error: "Failed to Delete Image",
        }
      );

      dispatch(fetchProductBySlug(product.slug));
    } catch (error) {
      toast.error("Failed to Delete Image");
    }
  };

  // const deleteVideoFromProduct = async (index) => {
  //   try {
  //     await toast.promise(
  //       axios.delete(
  //         `${process.env.REACT_APP_BACKEND_URL}/product/${product?._id}/videos/${index}`,
  //         {
  //           headers: {
  //             Authorization: token,
  //           },
  //         }
  //       ),
  //       {
  //         pending: "Deleting Video...",
  //         success: "Video Deleted Successfully!",
  //         error: "Failed to Delete Video.",
  //       }
  //     );

  //     dispatch(fetchProductBySlug(product.slug));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <>
      <div className="modal-body">
        <div className="product-details-reviews pt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="product-review-info">
                <ul className="nav review-tab">
                  <li>
                    <a
                      className={activeTab === "tab_one" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_one");
                      }}
                    >
                      details
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_two" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_two");
                      }}
                    >
                      Variants
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_three" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_three");
                      }}
                    >
                      Filter Options
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_four" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_four");
                      }}
                    >
                      Images
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className={activeTab === "tab_five" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_five");
                      }}
                    >
                      Videos
                    </a>
                  </li> */}
                </ul>
                <div className="tab-content reviews-tab">
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_one" ? "show active" : ""
                    } `}
                    id="tab_one"
                  >
                    <div
                      className="tab-one"
                      style={{ overflow: "auto", maxHeight: "50vh" }}
                    >
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "20%" }}>Title</td>
                            <td>
                              <input
                                type="text"
                                name="title"
                                value={updateDetailsData.title || ""}
                                onChange={updateDetailsHandler}
                                className="form-control mb-2"
                                placeholder="Name"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>description</td>
                            <td>
                              <textarea
                                rows={3}
                                name="description"
                                value={updateDetailsData.description || ""}
                                onChange={updateDetailsHandler}
                                className="form-control mb-2"
                                placeholder="Description"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Base Price</td>
                            <td>
                              <input
                                type="number"
                                name="basePrice"
                                value={updateDetailsData.basePrice || ""}
                                onChange={updateDetailsHandler}
                                className="form-control mb-2"
                                placeholder="Base Price"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Tags</td>
                            <td>
                              <div className="d-flex align-items-center gap-2 pb-2 flex-wrap">
                                {tags?.map((tag, index) => (
                                  <span
                                    key={tag + index}
                                    className="tags"
                                    onClick={() => {
                                      setTags((currData) => {
                                        let updatedData = [...currData];
                                        updatedData.splice(index, 1);
                                        return updatedData;
                                      });
                                    }}
                                  >
                                    {tag}
                                  </span>
                                ))}
                              </div>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  setTags((currData) => {
                                    let updatedData = [...currData];
                                    if (newTag) updatedData.push(newTag.trim());
                                    setNewTag("");
                                    return updatedData;
                                  });
                                }}
                              >
                                <input
                                  type="text"
                                  value={newTag || ""}
                                  onChange={(e) => setNewTag(e.target.value)}
                                  className="form-control mb-2"
                                  placeholder="Add new tag"
                                />
                              </form>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="featured"
                                    id="featured"
                                    checked={updateDetailsData?.featured}
                                    onChange={(e) => {
                                      setUpdateDetailsData({
                                        ...updateDetailsData,
                                        [e.target.name]: e.target.checked,
                                      });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    for={"featured"}
                                  >
                                    Featured
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="bestSeller"
                                    id="bestSeller"
                                    checked={updateDetailsData?.bestSeller}
                                    onChange={(e) => {
                                      setUpdateDetailsData({
                                        ...updateDetailsData,
                                        [e.target.name]: e.target.checked,
                                      });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    for={"bestSeller"}
                                  >
                                    Best Seller
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="onSale"
                                    id="onSale"
                                    checked={updateDetailsData?.onSale}
                                    onChange={(e) => {
                                      setUpdateDetailsData({
                                        ...updateDetailsData,
                                        [e.target.name]: e.target.checked,
                                      });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    for={"onSale"}
                                  >
                                    On Sale
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_two" ? "show active" : ""
                    } `}
                    id="tab_two"
                  >
                    <div
                      className="tab-two"
                      style={{ overflow: "auto", maxHeight: "50vh" }}
                    >
                      <table className="table ">
                        <tbody>
                          {product?.variants?.map((variant) => (
                            <VariantRow
                              variant={variant}
                              productId={product?._id}
                              slug={product?.slug}
                              key={variant._id}
                            />
                          ))}
                          {showNewVariant && (
                            <NewVariantRow
                              productId={product?._id}
                              slug={product?.slug}
                              setShowNewVariant={setShowNewVariant}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_three" ? "show active" : ""
                    } `}
                    id="tab_three"
                  >
                    <div
                      className="tab-three"
                      style={{ overflow: "auto", maxHeight: "50vh" }}
                    >
                      <table className="table ">
                        <tbody>
                          {filters?.map((filter, ind) => (
                            <tr key={filter?._id}>
                              <td style={{ width: "20%" }}>{filter?.name}</td>
                              <td>
                                <div className="d-flex gap-3 flex-wrap">
                                  {filter?.filterOptions?.map((option) => (
                                    <div
                                      className="custom-control custom-checkbox"
                                      key={option._id}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={option?._id}
                                        checked={selectedFilters?.includes(
                                          option?._id
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setSelectedFilters(
                                              (currFilters) => [
                                                ...currFilters,
                                                e.target.id,
                                              ]
                                            );
                                          else
                                            setSelectedFilters((currFilters) =>
                                              currFilters.filter(
                                                (option) =>
                                                  option !== e.target.id
                                              )
                                            );
                                        }}
                                      />
                                      <label
                                        className="custom-control-label"
                                        for={option._id}
                                      >
                                        {option?.name}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_four" ? "show active" : ""
                    } `}
                    id="tab_four"
                  >
                    <div
                      className="tab-four"
                      style={{ overflow: "auto", maxHeight: "50vh" }}
                    >
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "15%" }}>Uploaded Images</td>
                            <td>
                              <div className="d-flex gap-4 flex-wrap">
                                {product?.imageUrls?.map((url, ind) => (
                                  <ImageCard
                                    url={url}
                                    key={ind}
                                    backendUrl={
                                      process.env.REACT_APP_BACKEND_URL
                                    }
                                    deleteHandler={() => {
                                      deleteImageFromProduct(ind);
                                    }}
                                  />
                                ))}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>New Images</td>
                            <td>
                              <div className="d-flex gap-4 flex-wrap">
                                {uploadedImageUrls?.map((url, ind) => (
                                  <ImageCard
                                    url={url}
                                    key={ind}
                                    deleteHandler={() => {
                                      setImages((currImages) => {
                                        currImages.splice(ind, 1);
                                        const urlArr = currImages.map((image) =>
                                          URL.createObjectURL(image)
                                        );
                                        setUploadedImageUrls(urlArr);
                                        return currImages;
                                      });
                                    }}
                                  />
                                ))}
                              </div>
                              <div className="d-flex gap-4 flex-wrap mt-4">
                                <form className="form-container">
                                  <div
                                    className="dropzone"
                                    onClick={() => imgRef?.current?.click()}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragLeave={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                      e.preventDefault();
                                      if (e.dataTransfer.files[0]) {
                                        const imgArray = Array.from(
                                          e.dataTransfer.files
                                        );
                                        setImages(imgArray);
                                        const urlArr = imgArray.map((image) =>
                                          URL.createObjectURL(image)
                                        );
                                        setUploadedImageUrls(urlArr);
                                      }
                                    }}
                                  >
                                    Drop or Click to Upload Image
                                    <input
                                      type="file"
                                      accept=".jpg, .png, .jpeg, .gif"
                                      multiple
                                      hidden
                                      ref={imgRef}
                                      onChange={(e) => {
                                        if (e.currentTarget.files[0]) {
                                          const imgArray = Array.from(
                                            e.currentTarget.files
                                          );
                                          setImages(imgArray);
                                          const urlArr = imgArray.map((image) =>
                                            URL.createObjectURL(image)
                                          );
                                          setUploadedImageUrls(urlArr);
                                        }
                                      }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div
                    className={`tab-pane fade ${
                      activeTab === "tab_five" ? "show active" : ""
                    } `}
                    id="tab_five"
                  >
                    <div className="tab-five">
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "15%" }}>Uploaded Videos</td>
                            <td>
                              <div className="d-flex gap-4 flex-wrap">
                                {product?.videoUrls?.map((url, ind) => (
                                  <VideoCard
                                    url={url}
                                    key={url}
                                    backendUrl={
                                      process.env.REACT_APP_BACKEND_URL
                                    }
                                    deleteHandler={() =>
                                      deleteVideoFromProduct(ind)
                                    }
                                  />
                                ))}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>New Videos</td>
                            <td>
                              <div className={"d-flex gap-4 flex-wrap "}>
                                {uploadedVideoUrls?.map((url, ind) => (
                                  <VideoCard
                                    url={url}
                                    key={url}
                                    deleteHandler={() => {
                                      setVideos((currVideos) => {
                                        currVideos.splice(ind, 1);
                                        const urlArr = currVideos.map((image) =>
                                          URL.createObjectURL(image)
                                        );
                                        setUploadedVideoUrls(urlArr);
                                        return currVideos;
                                      });
                                    }}
                                  />
                                ))}
                              </div>
                              <div className="d-flex gap-4 flex-wrap ">
                                <form className="form-container">
                                  <div
                                    className="dropzone"
                                    onClick={() => vdoRef?.current?.click()}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragLeave={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                      e.preventDefault();
                                      if (e.dataTransfer.files[0]) {
                                        const vdoArray = Array.from(
                                          e.dataTransfer.files
                                        );
                                        setVideos(vdoArray);
                                        const urlArr = vdoArray.map((video) =>
                                          URL.createObjectURL(video)
                                        );
                                        setUploadedVideoUrls(urlArr);
                                      }
                                    }}
                                  >
                                    Drop or Click to Upload Videos
                                    <input
                                      type="file"
                                      accept=".mp4, .webm, .ogg"
                                      multiple
                                      hidden
                                      ref={vdoRef}
                                      onChange={(e) => {
                                        if (e.currentTarget.files[0]) {
                                          const vdoArray = Array.from(
                                            e.currentTarget.files
                                          );
                                          setVideos(vdoArray);
                                          const urlArr = vdoArray.map((video) =>
                                            URL.createObjectURL(video)
                                          );
                                          setUploadedVideoUrls(urlArr);
                                        }
                                      }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer" style={{ textTransform: "capitalize" }}>
        {activeTab === "tab_one" && (
          <button className="btn btn-sqr" onClick={updateProductDetails}>
            Update Details
          </button>
        )}
        {activeTab === "tab_two" && (
          <button
            className="btn btn-sqr"
            onClick={() => {
              setShowNewVariant(true);
            }}
            disabled={showNewVariant === true}
          >
            Add Variant
          </button>
        )}
        {activeTab === "tab_three" && (
          <button className="btn btn-sqr" onClick={updateProductFilterOptions}>
            Update Filter Options
          </button>
        )}
        {activeTab === "tab_four" && (
          <button
            className="btn btn-sqr"
            onClick={addImagesToProduct}
            disabled={images?.length <= 0}
          >
            Update Images
          </button>
        )}
        {/* {activeTab === "tab_five" && (
          <>
            <button
              className="btn btn-sqr"
              onClick={addVideosToProduct}
              disabled={videos?.length <= 0}
            >
              Upload Videos
            </button>
          </>
        )} */}
      </div>
    </>
  );
};

export default ProductDetailsTabs;
