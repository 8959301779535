import axios from "axios";
import React, { useEffect, useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";

const OrderSummary = ({
  items,
  handleFormSubmit,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  sdkReady,
  validateAddress,
}) => {
  const [convertedAmount, setConvertedAmount] = useState(null);

  const handlePaymentMethodChange = (event) => {
    const selectedMethod = event.target.value;
    if (!validateAddress()) return false;
    setSelectedPaymentMethod(selectedMethod);
  };

  const paymentMethods = [
    {
      id: "cashon",
      label: "Cash On Delivery",
      description: "Pay with cash upon delivery.",
    },
    {
      id: "paypalmode",
      label: "Pay with PayPal",
      description:
        "Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account.",
    },
  ];

  const calculateTotal = () => {
    const itemsTotal = items?.reduce((acc, item) => acc + item?.totalPrice, 0);
    return itemsTotal;
  };

  useEffect(() => {
    const fetchConversionRate = async () => {
      try {
        const response = await axios.get(
          `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/inr.json`
        );
        const rates = response.data.inr;
        const converted = calculateTotal() * rates.usd;
        setConvertedAmount(converted);
      } catch (error) {
        console.log("Error fetching exchange rates:", error);
      }
    };
    fetchConversionRate();
  }, []);

  return (
    <div className="order-summary-details">
      <h5 className="checkout-title">Your Order Summary</h5>
      <div className="order-summary-content">
        <div className="order-summary-table table-responsive text-center">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.product?.title}</td>
                  <td>{item?.quantity}</td>
                  <td>₹{item?.totalPrice?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">Total Amount</td>
                <td>₹{calculateTotal().toFixed(2)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="order-payment-method">
          {paymentMethods?.map((method) => (
            <div className="single-payment-method" key={method.id}>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id={method.id}
                  name="paymentmethod"
                  value={method.id}
                  className="custom-control-input"
                  checked={selectedPaymentMethod === method.id}
                  onChange={handlePaymentMethodChange}
                />
                <label className="custom-control-label" htmlFor={method.id}>
                  {method.label}
                </label>
              </div>
              {selectedPaymentMethod === method.id && (
                <div className="payment-method-details mt-2">
                  <p>{method.description}</p>
                </div>
              )}
            </div>
          ))}

          <div className="summary-footer-area">
            {selectedPaymentMethod === "cashon" && (
              <button
                type="submit"
                className="btn btn-sqr"
                onClick={() => {
                  validateAddress() && handleFormSubmit();
                }}
              >
                Place Order
              </button>
            )}
            {sdkReady &&
              selectedPaymentMethod === "paypalmode" &&
              convertedAmount !== null && (
                <PayPalButton
                  amount={
                    convertedAmount !== null
                      ? convertedAmount.toFixed(2)
                      : calculateTotal().toFixed(2)
                  }
                  onSuccess={handleFormSubmit}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
