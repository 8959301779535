import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductBanner = () => {
  const carouselSettings = {
    autoplay: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const bannerItems = [
    {
      imgSrc: "assets/img/banner/img1-middle.jpg",
      title: "BRACELETS",
    },
    {
      imgSrc: "assets/img/banner/img2-middle.jpg",
      title: "EARRINGS",
    },
    {
      imgSrc: "assets/img/banner/img3-middle.jpg",
      title: "NECKLACES",
    },
    {
      imgSrc: "assets/img/banner/img4-middle.jpg",
      title: "RINGS",
    },
    {
      imgSrc: "assets/img/banner/img5-middle.jpg",
      title: "PEARLS",
    },
  ];

  return (
    <section className="product-banner-statistics">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="product-banner-carousel ">
              <Slider {...carouselSettings}>
                {bannerItems?.map((item, index) => (
                  <div key={index} className="banner-slide-item">
                    <figure className="banner-statistics">
                      <a href="#">
                        <img src={item.imgSrc} alt="product banner" />
                      </a>
                      <div className="banner-content banner-content_style2">
                        <h5 className="banner-text3">
                          <a href="#">{item.title}</a>
                        </h5>
                      </div>
                    </figure>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductBanner;
