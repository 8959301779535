import React from "react";

const bannersData = [
  {
    imgSrc: "/assets/img/banner/img1-top.jpg",
    text1: "BEAUTIFUL",
    text2: "Wedding",
    text3: "Rings",
  },
  {
    imgSrc: "/assets/img/banner/img2-top.jpg",
    text1: "EARRINGS",
    text2: "Tangerine Floral",
    text3: "Earring",
  },
  {
    imgSrc: "/assets/img/banner/img3-top.jpg",
    text1: "NEW ARRIVALLS",
    text2: "Pearl",
    text3: "Necklaces",
  },
  {
    imgSrc: "/assets/img/banner/img4-top.jpg",
    text1: "NEW DESIGN",
    text2: "Diamond",
    text3: "Jewelry",
  },
];

const BannerStatisticsArea = () => {
  return (
    <div className="banner-statistics-area">
      <div className="container">
        <div className="row row-20 mtn-20">
          {bannersData?.map((banner, index) => (
            <BannerComponent key={index} {...banner} />
          ))}
        </div>
      </div>
    </div>
  );
};

const BannerComponent = ({ imgSrc, text1, text2, text3 }) => {
  return (
    <div className="col-sm-6">
      <figure className="banner-statistics mt-20">
        <a href="#">
          <img src={imgSrc} alt="product banner" />
        </a>
        <div className="banner-content text-center">
          <h5 className="banner-text1">{text1}</h5>
          <h2 className="banner-text2">
            {text2}
            <span>{text3}</span>
          </h2>
          <a href="shop.html" className="btn btn-text">
            Shop Now
          </a>
        </div>
      </figure>
    </div>
  );
};

export default BannerStatisticsArea;
