import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, updateCartItem } from "../../redux/CartSlice";
import { Link } from "react-router-dom";
import pathToUrl from "../PathToUrl";



const CartItem = ({ item, adminView }) => {
  const { token } = useSelector((state) => state.user);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(item.quantity);
  const dispatch = useDispatch();
  useEffect(() => {
    setPrice(item.totalPrice / item.quantity);
    setQuantity(item.quantity);
  }, [item]);

  const onRemove = (id) => {
    dispatch(removeFromCart({ token, id }));
  };

  const changeQuantity = (id, quantity) => {
    if (quantity < 1) {
      dispatch(removeFromCart({ token, id }));
    } else {
      dispatch(updateCartItem({ token, id, updatedData: { quantity } }));
    }
  };

  return (
    <tr>
      <td className="pro-thumbnail">
        <a href="#">
          <img
            className="img-fluid"
            src={
              item?.product?.imageUrls && pathToUrl(item?.product?.imageUrls[0])
            }
            alt={item?.product?.title}
          />
        </a>
      </td>
      <td className="pro-title">
        <Link to={`/${item?.product?.slug}`}>
          {item?.product?.title} -{" "}
          {item.options.reduce(
            (acc, option, ind) =>
              acc +
              option.selectedOption.name +
              (ind !== item?.options?.length - 1 ? ", " : ""),
            ""
          )}
        </Link>
      </td>
      <td className="pro-price">
        <span>₹{price}</span>
      </td>
      <td className="pro-quantity">
        <div className="pro-qty">
          {!adminView && (
            <span
              className="dec qtybtn"
              onClick={() => changeQuantity(item?._id, quantity - 1)}
            >
              -
            </span>
          )}
          <input type="text" value={quantity} readOnly={true} />
          {!adminView && (
            <span
              className="inc qtybtn"
              onClick={() => changeQuantity(item?._id, quantity + 1)}
            >
              +
            </span>
          )}
        </div>
      </td>
      <td className="pro-subtotal">
        <span>₹{item.totalPrice}</span>
      </td>
      {!adminView && (
        <td className="pro-remove">
          <button onClick={() => onRemove(item._id)}>
            <i className="fa fa-trash-o"></i>
          </button>
        </td>
      )}
    </tr>
  );
};

export default CartItem;
