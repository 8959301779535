import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/UserSlice";
import { clearCart, fetchCartItems, removeFromCart } from "../redux/CartSlice";
import { clearWishlist, fetchWishlistItems } from "../redux/WishlistSlice";
import Headroom from "react-headroom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pathToUrl from "./PathToUrl";
import { IconContext } from "react-icons";
import { BsFillCartXFill } from "react-icons/bs";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { auth, token } = useSelector((state) => state.user);
  const { cartLength, items } = useSelector((state) => state.cart);
  const { wishlistLength } = useSelector((state) => state.wishlist);
  const [totalPrice, setTotalPrice] = useState(0);
  const [searchString, setSearchString] = useState(
    searchParams.get("que") || ""
  );
  const location = useLocation();

  const handleLogout = () => {
    navigate("/login");
    dispatch(logout());
    dispatch(clearCart());
    dispatch(clearWishlist());
  };

  const onRemove = async (id) => {
    try {
      await toast.promise(dispatch(removeFromCart({ token, id })), {
        pending: "Removing item...",
        success: "Item removed successfully!",
        error: "Failed to remove item.",
      });
    } catch (error) {
      toast.error("Failed to remove item. Please try again.");
    }
  };

  const search = (e) => {
    e.preventDefault();
    navigate({ pathname: "/shop", search: `?que=${searchString}` });
  };

  const closeModal = () => {
    $("body").removeClass("fix");
    $(".off-canvas-wrapper").removeClass("open");
  };
  useEffect(() => {
    if (auth) {
      dispatch(fetchCartItems(token));
      dispatch(fetchWishlistItems(token));
    }
  }, [auth]);

  useEffect(() => {
    setTotalPrice(items?.reduce((acc, item) => acc + item.totalPrice, 0));
  }, [items]);

  useLayoutEffect(() => {
    $(".mobile-menu-btn").on("click", function () {
      $("body").addClass("fix");
      $(".off-canvas-wrapper").addClass("open");
    });

    $(".btn-close-off-canvas,.off-canvas-overlay").on("click", function () {
      $("body").removeClass("fix");
      $(".off-canvas-wrapper").removeClass("open");
    });

    $(".minicart-btn").on("click", function () {
      $("body").addClass("fix");
      $(".minicart-inner").addClass("show");
    });

    $(".offcanvas-close, .minicart-close,.offcanvas-overlay").on(
      "click",
      function () {
        $("body").removeClass("fix");
        $(".minicart-inner").removeClass("show");
      }
    );
  }, []);

  return (
    <>
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      {/* <Headroom> */}
      <header className="header-area header-wide">
        <Headroom>
          <div
            className="main-header d-none d-lg-block"
            style={{ backgroundColor: "white", zIndex: 999999 }}
          >
            <div className="header-main-area sticky">
              <div className="container">
                <div className="row align-items-center position-relative">
                  <div className="col-lg-2">
                    <div className="logo">
                      <Link to="/">
                        <img src="/assets/img/logo/logo.png" alt="Brand Logo" />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-6 position-static">
                    <div className="main-menu-area">
                      <div className="main-menu">
                        <nav className="desktop-menu">
                          <ul>
                            <li
                              className={
                                (location?.pathname === "/" && "active") || ""
                              }
                            >
                              <Link to="/">Home</Link>
                            </li>
                            <li
                              className={
                                (location?.pathname === "/about-us" &&
                                  "active") ||
                                ""
                              }
                            >
                              <Link to="/about-us">About us</Link>
                            </li>
                            <li
                              className={
                                (location?.pathname === "/shop" && "active") ||
                                ""
                              }
                            >
                              <Link to="/shop">shop</Link>
                            </li>
                            <li
                              className={
                                (location?.pathname === "/contact-us" &&
                                  "active") ||
                                ""
                              }
                            >
                              <Link to="/contact-us">Contact us</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="header-right d-flex align-items-center justify-content-xl-between justify-content-lg-end">
                      <div className="header-search-container">
                        <form
                          className="header-search-box d-lg-none d-xl-block"
                          onSubmit={search}
                        >
                          <input
                            type="text"
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            placeholder="Search entire store here"
                            className="header-search-field"
                          />
                          <button type="submit" className="header-search-btn">
                            <i className="pe-7s-search"></i>
                          </button>
                        </form>
                      </div>
                      <div className="header-configure-area">
                        <ul className="nav justify-content-end">
                          <li className="user-hover">
                            <Link to="#">
                              <i className="pe-7s-user"></i>
                            </Link>
                            <ul className="dropdown-list">
                              {!auth ? (
                                <>
                                  <li>
                                    <Link to="login">login</Link>
                                  </li>
                                  <li>
                                    <Link to="register">register</Link>
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li>
                                    <Link to={"/login"} onClick={handleLogout}>
                                      logout
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="my-account">my account</Link>
                                  </li>
                                </>
                              )}
                            </ul>
                          </li>
                          <li>
                            <Link to="wishlist">
                              <i className="pe-7s-like"></i>
                              <div className="notification">
                                {wishlistLength || 0}
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="minicart-btn">
                              <i className="pe-7s-shopbag"></i>
                              <div className="notification">
                                {cartLength || 0}
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Headroom>

        <div className="mobile-header d-lg-none d-md-block sticky">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="mobile-main-header">
                  <div className="mobile-logo">
                    <Link to="/">
                      <img src="/assets/img/logo/logo.png" alt="Brand Logo" />
                    </Link>
                  </div>
                  <div className="mobile-menu-toggler">
                    <div className="mini-cart-wrap">
                      <Link to="cart">
                        <i className="pe-7s-shopbag"></i>
                        <div className="notification">{cartLength || 0}</div>
                      </Link>
                    </div>
                    <button className="mobile-menu-btn">
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <aside className="off-canvas-wrapper">
          <div className="off-canvas-overlay"></div>
          <div className="off-canvas-inner-content">
            <div className="btn-close-off-canvas">
              <i className="pe-7s-close"></i>
            </div>

            <div className="off-canvas-inner">
              <div className="search-box-offcanvas">
                <form onSubmit={search}>
                  <input
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    placeholder="Search entire store here"
                    type="text"
                  />
                  <button className="search-btn">
                    <i className="pe-7s-search"></i>
                  </button>
                </form>
              </div>

              <div className="mobile-navigation">
                <nav>
                  <ul className="mobile-menu">
                    <li>
                      <Link onClick={closeModal} to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={closeModal} to="/about-us">
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>

                    <li>
                      <Link to="/contact-us">Contact us</Link>
                    </li>
                    {auth ? (
                      <>
                        <li>
                          <Link
                            to={"/login"}
                            onClick={() => {
                              handleLogout();
                              closeModal();
                            }}
                          >
                            logout
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={closeModal}
                            to="my-account"
                          >
                            My account
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={closeModal}
                            to="login"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={closeModal}
                            to="register"
                          >
                            Register
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>

              <div className="offcanvas-widget-area">
                <div className="off-canvas-contact-widget">
                  <ul>
                    <li>
                      <i className="fa fa-mobile"></i>
                      <Link to="#">0123456789</Link>
                    </li>
                    <li>
                      <i className="fa fa-envelope-o"></i>
                      <Link to="#">info@yourdomain.com</Link>
                    </li>
                  </ul>
                </div>
                <div className="off-canvas-social-widget">
                  <Link to="#">
                    <i className="fa fa-facebook"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-twitter"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-pinterest-p"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-linkedin"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-youtube-play"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </header>
      {/* </Headroom> */}
      {/* <div className="modal" id="quick_view">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-bs-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="product-details-inner">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="product-large-slider">
                      <div className="pro-large-img img-zoom">
                        <img
                          src="/assets/img/product/product-details-img1.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-large-img img-zoom">
                        <img
                          src="/assets/img/product/product-details-img2.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-large-img img-zoom">
                        <img
                          src="/assets/img/product/product-details-img3.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-large-img img-zoom">
                        <img
                          src="/assets/img/product/product-details-img4.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-large-img img-zoom">
                        <img
                          src="/assets/img/product/product-details-img5.jpg"
                          alt="product-details"
                        />
                      </div>
                    </div>
                    <div className="pro-nav slick-row-10 slick-arrow-style">
                      <div className="pro-nav-thumb">
                        <img
                          src="/assets/img/product/product-details-img1.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-nav-thumb">
                        <img
                          src="/assets/img/product/product-details-img2.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-nav-thumb">
                        <img
                          src="/assets/img/product/product-details-img3.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-nav-thumb">
                        <img
                          src="/assets/img/product/product-details-img4.jpg"
                          alt="product-details"
                        />
                      </div>
                      <div className="pro-nav-thumb">
                        <img
                          src="/assets/img/product/product-details-img5.jpg"
                          alt="product-details"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="product-details-des">
                      <div className="manufacturer-name">
                        <Link to="shop/Product-1">HasTech</Link>
                      </div>
                      <h3 className="product-name">Handmade Golden Necklace</h3>
                      <div className="ratings d-flex">
                        <span>
                          <i className="fa fa-star-o"></i>
                        </span>
                        <span>
                          <i className="fa fa-star-o"></i>
                        </span>
                        <span>
                          <i className="fa fa-star-o"></i>
                        </span>
                        <span>
                          <i className="fa fa-star-o"></i>
                        </span>
                        <span>
                          <i className="fa fa-star-o"></i>
                        </span>
                        <div className="pro-review">
                          <span>1 Reviews</span>
                        </div>
                      </div>
                      <div className="price-box">
                        <span className="price-regular">$70.00</span>
                        <span className="price-old">
                          <del>$90.00</del>
                        </span>
                      </div>
                      <h5 className="offer-text">
                        <strong>Hurry up</strong>! offer ends in:
                      </h5>
                      <div
                        className="product-countdown"
                        data-countdown="2022/12/20"
                      ></div>
                      <div className="availability">
                        <i className="fa fa-check-circle"></i>
                        <span>200 in stock</span>
                      </div>
                      <p className="pro-desc">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna.
                      </p>
                      <div className="quantity-cart-box d-flex align-items-center">
                        <h6 className="option-title">qty:</h6>
                        <div className="quantity">
                          <div className="pro-qty">
                            <input type="text" />
                          </div>
                        </div>
                        <div className="action_link">
                          <Link className="btn btn-cart2" to="#">
                            Add to cart
                          </Link>
                        </div>
                      </div>
                      <div className="useful-links">
                        <Link to="#" data-bs-toggle="tooltip" title="Compare">
                          <i className="pe-7s-refresh-2"></i>compare
                        </Link>
                        <Link to="#" data-bs-toggle="tooltip" title="Wishlist">
                          <i className="pe-7s-like"></i>wishlist
                        </Link>
                      </div>
                      <div className="like-icon">
                        <Link className="facebook" to="#">
                          <i className="fa fa-facebook"></i>like
                        </Link>
                        <Link className="twitter" to="#">
                          <i className="fa fa-twitter"></i>tweet
                        </Link>
                        <Link className="pinterest" to="#">
                          <i className="fa fa-pinterest"></i>save
                        </Link>
                        <Link className="google" to="#">
                          <i className="fa fa-google-plus"></i>share
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="offcanvas-minicart-wrapper">
        <div className="minicart-inner">
          <div className="offcanvas-overlay"></div>
          <div className="minicart-inner-content">
            <div className="minicart-close">
              <i className="pe-7s-close"></i>
            </div>
            {cartLength > 0 ? (
              <div className="minicart-content-box">
                <div className="minicart-item-wrapper">
                  <ul>
                    {items?.map((item, index) => {
                      return (
                        <li className="minicart-item" key={item._id || index}>
                          <div className="minicart-thumb">
                            <Link to={`shop/${item?.product?.slug}`}>
                              <img
                                src={
                                  item?.product?.imageUrls &&
                                  pathToUrl(item?.product?.imageUrls[0])
                                }
                                alt="product"
                              />
                            </Link>
                          </div>
                          <div className="minicart-content">
                            <h3 className="product-name">
                              <Link to={`shop/${item?.product?.slug}`}>
                                {item?.product?.title} -{" "}
                                {item.options.reduce(
                                  (acc, option, ind) =>
                                    acc +
                                    option?.selectedOption?.name +
                                    (ind !== item?.options?.length - 1
                                      ? ", "
                                      : ""),
                                  ""
                                )}
                              </Link>
                            </h3>
                            <p>
                              <span className="cart-quantity">
                                {item.quantity} <strong>&times;</strong>
                              </span>
                              <span className="cart-price">
                                ₹{item.totalPrice / item.quantity}
                              </span>
                            </p>
                          </div>
                          <button
                            className="minicart-remove"
                            onClick={() => onRemove(item?._id)}
                          >
                            <i className="pe-7s-close"></i>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="minicart-pricing-box">
                  <ul>
                    <li className="total">
                      <span>total</span>
                      <span>
                        <strong>₹{totalPrice?.toFixed(2)}</strong>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="minicart-button">
                  <Link to="/cart" onClick={() => closeModal()}>
                    <i className="fa fa-shopping-cart"></i> View Cart
                  </Link>
                  <Link to="/checkout" onClick={() => closeModal()}>
                    <i className="fa fa-share"></i> Checkout
                  </Link>
                </div>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center gap-4"
                style={{ height: "80vh" }}
              >
                <IconContext.Provider value={{ color: "#C29958" }}>
                  <BsFillCartXFill size={100} />
                </IconContext.Provider>
                <p>
                  Nothing in Cart. Please select from the{" "}
                  <Link to="/shop" style={{ color: "#C29958" }}>
                    Shop
                  </Link>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
