import React from "react";
import ProductItem, { ProductListItem } from "../ProductItem";

const ProductList = ({ products, viewMode }) => {
  return (
    <div className={`shop-product-wrap ${viewMode} row mbn-30`}>
      {products?.map((product) => (
        <div
          className={`col-md-4 col-sm-6 ${
            viewMode === "list-view" ? "col-lg-12" : ""
          }`}
          key={product._id}
        >
          {viewMode === "grid-view" ? (
            <ProductItem {...product} />
          ) : (
            <ProductListItem {...product} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductList;
