import axios from "axios";
import { useState, useRef } from "react";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const VariantOptionRow = ({
  option,
  deleteNewOption,
  deleteVariantOption,
  updateNewOption,
  updateVariantOption,
}) => {
  const [updatedOption, setUpdatedOption] = useState({});
  const [nameEditMode, setNameEditMode] = useState(false);
  const [rateEditMode, setRateEditMode] = useState(false);

  useState(() => {
    setUpdatedOption(option);
  }, [option]);

  const optionChangeHandler = (e) => {
    setUpdatedOption((currOption) => {
      let changedOption;
      if (e.target.type === "checkbox") {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.checked,
        };
      } else {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.value,
        };
      }
      return changedOption;
    });
  };
  const updateVariantOptionHandler = async () => {
    const { _id, __v, ...data } = updatedOption;

    updateVariantOption(data);
    setNameEditMode(false);
    setRateEditMode(false);
  };

  const updateNewVariantOption = () => {
    updateNewOption(updatedOption);
    setNameEditMode(false);
    setRateEditMode(false);
  };

  return (
    <tr key={updatedOption?._id}>
      <td style={{ width: "30%" }}>
        <div className="d-flex gap-2 align-items-center">
          {nameEditMode ? (
            <>
              <input
                type="text"
                name="name"
                value={updatedOption?.name || ""}
                onChange={optionChangeHandler}
                className="form-control mb-2"
                placeholder="Name"
              />
            </>
          ) : (
            <>
              <p style={{ marginTop: "10px" }}>{updatedOption?.name}</p>
              <AiFillEdit
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNameEditMode(true);
                }}
              />
            </>
          )}
        </div>
      </td>
      <td>
        <div className="d-flex gap-2 align-items-center">
          {rateEditMode ? (
            <>
              <input
                type="number"
                name="incRate"
                value={updatedOption?.incRate || ""}
                onChange={optionChangeHandler}
                className="form-control mb-2"
                placeholder="Inc Rate"
              />
            </>
          ) : (
            <>
              <p style={{ marginTop: "10px" }}>{updatedOption?.incRate}</p>
              <AiFillEdit
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setRateEditMode(true);
                }}
              />
            </>
          )}
        </div>
      </td>
      <td>
        <div className="custom-control custom-checkbox d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="custom-control-input"
            name="isActive"
            id={updatedOption?._id + 1}
            checked={updatedOption?.isActive}
            onChange={optionChangeHandler}
          />
          <label
            className="custom-control-label"
            for={updatedOption?._id + 1}
          ></label>
        </div>
      </td>
      <td>
        <div className="custom-control custom-checkbox ">
          <input
            type="checkbox"
            className="custom-control-input"
            name="inStock"
            id={updatedOption?._id + 2}
            checked={updatedOption?.inStock}
            onChange={optionChangeHandler}
          />
          <label
            className="custom-control-label"
            for={updatedOption?._id + 2}
          ></label>
        </div>
      </td>
      <td className="d-flex justify-content-center align-items-center gap-2">
        {!option?._id?.includes("new") ? (
          <>
            <button
              onClick={updateVariantOptionHandler}
              className="btn btn-sqr"
              disabled={
                JSON.stringify(updatedOption) === JSON.stringify(option)
              }
            >
              Update
            </button>
            <button onClick={deleteVariantOption} className="btn btn-sqr">
              Delete
            </button>
          </>
        ) : (
          <>
            <button
              onClick={updateNewVariantOption}
              className="btn btn-sqr"
              disabled={
                JSON.stringify(updatedOption) === JSON.stringify(option)
              }
            >
              Update
            </button>
            <button onClick={deleteNewOption} className="btn btn-sqr">
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

const VariantRow = ({ variant, index, setVariants }) => {
  const [nameEditMode, setNameEditMode] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [mouseIn, setMouseIn] = useState(false);
  const [options, setOptions] = useState([]);
  const [newOptionData, setNewOptionData] = useState({
    incRate: "",
    name: "",
    isActive: true,
    inStock: true,
  });
  const [showNewOption, setShowNewOption] = useState(false);

  useEffect(() => {
    setVariantName(variant?.type);
    setOptions(variant?.options);
  }, [variant]);

  const updateVariantName = async () => {
    setVariants((currVariants) => {
      currVariants[index].type = variantName;
      return currVariants;
    });
    setNameEditMode(false);
  };

  const createVariantOption = async () => {
    setVariants((currVariants) => {
      currVariants[index].options.push(newOptionData);
      setOptions(currVariants[index]?.options);
      return currVariants;
    });
    setNewOptionData({
      incRate: "",
      name: "",
      isActive: true,
      inStock: true,
    });
    setShowNewOption(false);
  };

  const deleteVariantOption = async (optionIndex) => {
    setVariants((currVariants) => {
      currVariants[index].options = currVariants[index].options.filter(
        (option, ind) => ind !== optionIndex
      );
      setOptions(currVariants[index]?.options);
      return currVariants;
    });
  };

  const updateVariantOption = async (optionIndex, updatedData) => {
    setVariants((currVariants) => {
      currVariants[index].options = currVariants[index]?.options?.map(
        (option, ind) => {
          if (ind === optionIndex) {
            return updatedData;
          }
          return option;
        }
      );

      setOptions(currVariants[index]?.options);
      return currVariants;
    });
  };

  const deleteVariantFromProduct = async () => {
    setVariants((currVariants) => {
      return currVariants.filter((variant, ind) => ind !== index);
    });
  };

  const newOptionChangeHandler = (e) => {
    setNewOptionData((currOption) => {
      let changedOption;
      if (e.target.type === "checkbox") {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.checked,
        };
      } else {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.value,
        };
      }
      return changedOption;
    });
  };

  return (
    <tr
      key={variant._id}
      onMouseEnter={() => {
        setMouseIn(true);
      }}
      onMouseLeave={() => {
        setMouseIn(false);
      }}
    >
      <td
        className={`add-row ${mouseIn ? "show" : ""}`}
        style={{ border: "none" }}
        onClick={deleteVariantFromProduct}
        rowSpan={2}
      >
        <AiFillDelete size={20} />
      </td>
      <td style={{ width: "20%" }}>
        <div className="d-flex  align-items-center gap-2">
          {nameEditMode ? (
            <>
              <input
                type="text"
                value={variantName || ""}
                onChange={(e) => setVariantName(e.target.value)}
                className="form-control mb-2"
                placeholder="Name"
              />
              <AiOutlineCloseCircle
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNameEditMode(false);
                  setVariantName(variant?.type);
                }}
              />
              <AiOutlineCheckCircle
                size={25}
                style={{ cursor: "pointer" }}
                onClick={updateVariantName}
              />
            </>
          ) : (
            <>
              <p style={{ marginTop: "10px" }}>{variantName}</p>
              <AiFillEdit
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNameEditMode(true);
                }}
              />
            </>
          )}
        </div>
      </td>
      <td>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Option Name</th>
              <th>Inc Rate</th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Active
              </th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Stock
              </th>
              <th
                style={{
                  width: "25%",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {options?.map((option, ind) => {
              return (
                <VariantOptionRow
                  option={{ ...option, _id: ind + option?.name }}
                  key={option?.name + ind}
                  deleteVariantOption={() => {
                    deleteVariantOption(ind);
                  }}
                  updateVariantOption={(data) => {
                    updateVariantOption(ind, data);
                  }}
                />
              );
            })}
            {showNewOption && (
              <tr key={newOptionData?._id}>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={newOptionData?.name || ""}
                    onChange={newOptionChangeHandler}
                    className="form-control mb-2"
                    placeholder="Name"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="incRate"
                    value={newOptionData?.incRate || ""}
                    onChange={newOptionChangeHandler}
                    className="form-control mb-2"
                    placeholder="Inc Rate"
                  />
                </td>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="isActive"
                      className="custom-control-input"
                      id={variant?._id + "new" + 1}
                      checked={newOptionData?.isActive}
                      onChange={newOptionChangeHandler}
                    />
                    <label
                      className="custom-control-label"
                      for={variant?._id + "new" + 1}
                    ></label>
                  </div>
                </td>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="inStock"
                      className="custom-control-input"
                      id={variant?._id + "new" + 2}
                      checked={newOptionData?.inStock}
                      onChange={newOptionChangeHandler}
                    />
                    <label
                      className="custom-control-label"
                      for={variant?._id + "new" + 2}
                    ></label>
                  </div>
                </td>
                <td className="d-flex justify-content-center align-items-center gap-2">
                  <button
                    onClick={createVariantOption}
                    className="btn btn-sqr"
                    disabled={!newOptionData?.name}
                  >
                    Add
                  </button>
                  <button
                    onClick={() => {
                      setShowNewOption(false);
                    }}
                    className="btn btn-sqr"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!showNewOption && (
          <div
            className={`add-row ${mouseIn ? "show" : ""}`}
            onClick={() => {
              setShowNewOption(true);
            }}
          >
            <AiOutlinePlusCircle size={20} />
            <AiOutlinePlusCircle size={20} />
          </div>
        )}
      </td>
    </tr>
  );
};

const NewVariantRow = ({ setShowNewVariant, setVariants }) => {
  const [variantName, setVariantName] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);
  const [mouseIn, setMouseIn] = useState(false);
  const [newOptionData, setNewOptionData] = useState({
    incRate: "",
    name: "",
    isActive: true,
    inStock: true,
  });

  const newOptionChangeHandler = (e) => {
    setNewOptionData((currOption) => {
      let changedOption;
      if (e.target.type === "checkbox") {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.checked,
        };
      } else {
        changedOption = {
          ...currOption,
          [e.target.name]: e.target.value,
        };
      }
      return changedOption;
    });
  };

  const addVariantToProduct = async () => {
    if (!variantName || !variantOptions.length) return;
    const options = variantOptions.map((option) => {
      const { _id, ...data } = option;
      return data;
    });
    const variantData = { type: variantName, options };
    setVariants((currVariants) => [...currVariants, variantData]);
    setShowNewVariant(false);
  };

  const deleteVariantOption = (ind) => {
    setVariantOptions((currOption) => {
      let updatedData = [...currOption];
      updatedData.splice(ind, 1);
      return updatedData;
    });
  };

  const updateVariantOption = (updatedOption, ind) => {
    setVariantOptions((currOption) => {
      let updatedData = currOption?.map((option, i) => {
        if (i !== ind) {
          return option;
        }
        return updatedOption;
      });

      return updatedData;
    });
  };

  return (
    <tr
      onMouseEnter={() => {
        setMouseIn(true);
      }}
      onMouseLeave={() => {
        setMouseIn(false);
      }}
    >
      <td
        className={`add-row ${mouseIn ? "show" : ""}`}
        style={{ border: "none" }}
        onClick={addVariantToProduct}
        rowSpan={2}
      >
        <AiOutlineCheck size={20} />
      </td>
      <td
        className={`add-row ${mouseIn ? "show" : ""}`}
        style={{ border: "none" }}
        onClick={() => {
          setShowNewVariant(false);
        }}
        rowSpan={2}
      >
        <AiFillDelete size={20} />
      </td>
      <td style={{ width: "20%" }}>
        <div className="d-flex align-items-center gap-2">
          <>
            <input
              type="text"
              value={variantName || ""}
              onChange={(e) => setVariantName(e.target.value)}
              className="form-control mb-2"
              placeholder="Name"
            />
          </>
        </div>
      </td>
      <td>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Option Name</th>
              <th>Inc Rate</th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Active
              </th>
              <th
                style={{
                  width: "8%",
                }}
              >
                Stock
              </th>
              <th
                style={{
                  width: "25%",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {variantOptions?.map((option, ind) => {
              return (
                <VariantOptionRow
                  option={option}
                  key={option._id}
                  deleteOption={() => {
                    deleteVariantOption(ind);
                  }}
                  updateNewOption={(updatedOption) => {
                    updateVariantOption(updatedOption, ind);
                  }}
                />
              );
            })}
            <tr key={newOptionData?._id}>
              <td>
                <input
                  type="text"
                  name="name"
                  value={newOptionData?.name || ""}
                  onChange={newOptionChangeHandler}
                  className="form-control mb-2"
                  placeholder="Name"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="incRate"
                  value={newOptionData?.incRate || ""}
                  onChange={newOptionChangeHandler}
                  className="form-control mb-2"
                  placeholder="Inc Rate"
                />
              </td>
              <td>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="isActive"
                    className="custom-control-input"
                    id={"newOpt" + 1}
                    checked={newOptionData?.isActive}
                    onChange={newOptionChangeHandler}
                  />
                  <label
                    className="custom-control-label"
                    for={"newOpt" + 1}
                  ></label>
                </div>
              </td>
              <td>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="inStock"
                    className="custom-control-input"
                    id={"newOpt" + 2}
                    checked={newOptionData?.inStock}
                    onChange={newOptionChangeHandler}
                  />
                  <label
                    className="custom-control-label"
                    for={"newOpt" + 2}
                  ></label>
                </div>
              </td>
              <td className="d-flex justify-content-center align-items-center gap-2">
                <button
                  onClick={() => {
                    setNewOptionData((currData) => {
                      setVariantOptions((currOptions) => [
                        ...currOptions,
                        {
                          ...currData,
                          _id: "new" + variantOptions.length,
                        },
                      ]);
                      return currData;
                    });
                    setNewOptionData({
                      incRate: "",
                      name: "",
                      isActive: true,
                      inStock: true,
                    });
                  }}
                  className="btn btn-sqr"
                  disabled={!newOptionData?.name}
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const ImageCard = ({ url, deleteHandler, backendUrl }) => {
  const [mouseIn, setMouseIn] = useState(false);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (backendUrl) {
      setImageURL(encodeURI(backendUrl + "/" + url.replaceAll("\\", "/")));
    } else {
      setImageURL(url);
    }
  }, [url, backendUrl]);

  return (
    <>
      {imageURL && (
        <div
          className="uploaded-images"
          style={{
            backgroundImage: `url(${imageURL})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onMouseEnter={() => {
            setMouseIn(true);
          }}
          onMouseLeave={() => {
            setMouseIn(false);
          }}
        >
          {mouseIn && (
            <div className="overlay" onClick={deleteHandler}>
              <AiFillDelete size={20} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

// const VideoCard = ({ url, deleteHandler, backendUrl }) => {
//   const [mouseIn, setMouseIn] = useState(false);
//   const [videoURL, setVideoURL] = useState("");

//   useEffect(() => {
//     if (backendUrl) {
//       setVideoURL(encodeURI(backendUrl + "/" + url.replaceAll("\\", "/")));
//     } else {
//       setVideoURL(url);
//     }
//   }, [url, backendUrl]);

//   return (
//     <div
//       className="uploaded-videos col-3"
//       onMouseEnter={() => setMouseIn(true)}
//       onMouseLeave={() => setMouseIn(false)}
//     >
//       {videoURL && (
//         <video className="video-preview" controls>
//           <source src={videoURL} type="video/mp4" />
//           {/* <source src={videoURL} type="video/webm" />
//           <source src={videoURL} type="video/ogg" /> */}
//           Your browser does not support the video tag.
//         </video>
//       )}
//       {mouseIn && (
//         <div className="overlay" onClick={deleteHandler}>
//           <AiFillDelete size={20} />
//         </div>
//       )}
//     </div>
//   );
// };

function ProductDetailsTabs({ filters, reloadPage, closeModal }) {
  const { token } = useSelector((state) => state.user);
  const imgRef = useRef();
  // const vdoRef = useRef();
  const [activeTab, setActiveTab] = useState("tab_one");
  const [product, setProduct] = useState({});
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [variants, setVariants] = useState([]);
  const [showNewVariant, setShowNewVariant] = useState(false);
  const [images, setImages] = useState([]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [videos, setVideos] = useState([]);
  // const [uploadedVideoUrls, setUploadedVideoUrls] = useState([]);

  const updateDetailsHandler = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  const addNewProduct = async () => {
    if (!product.title) {
      return toast.error("Please enter a valid title");
    } else if (!product.basePrice) {
      return toast.error("Please enter a valid base price");
    } else if (!images?.length) {
      return toast.error("Please upload atleast one image");
    }
    try {
      await toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            const formData = new FormData();
            formData.append("title", product.title);
            formData.append("description", product.description);
            formData.append("basePrice", product.basePrice);
            formData.append("featured", product.featured);
            formData.append("bestSeller", product.bestSeller);
            formData.append("onSale", product.onSale);

            tags.forEach((tag) => {
              formData.append("tags", tag);
            });

            selectedFilters.forEach((filterId) => {
              formData.append("filterOptions", filterId);
            });

            formData.append("variants", JSON.stringify(variants));

            images.forEach((image) => {
              formData.append("images", image);
            });

            videos.forEach((video) => {
              formData.append("videos", video);
            });

            const res = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/product`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: token,
                },
              }
            );
            if (res.status === 201) {
              resolve("Product Added Successfully!");
              reloadPage();
              closeModal();
            } else {
              reject("Failed to Add Product.");
            }
          } catch (error) {
            reject(error);
          }
        }),
        {
          pending: "Adding Product...",
          success: "Product Added Successfully!",
          error: "Failed to Add Product.",
        }
      );
    } catch (error) {
      toast.error("Failed to Add Product");
    }
  };

  return (
    <>
      <div className="modal-body">
        <div className="product-details-reviews pt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="product-review-info">
                <ul className="nav review-tab">
                  <li>
                    <a
                      className={activeTab === "tab_one" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_one");
                      }}
                    >
                      details
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_two" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_two");
                      }}
                    >
                      Variants
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_three" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_three");
                      }}
                    >
                      Filter Options
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_four" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_four");
                      }}
                    >
                      {/* Images & Videos */}
                      Images
                    </a>
                  </li>
                </ul>
                <div className="tab-content reviews-tab">
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_one" ? "show active" : ""
                    } `}
                    id="tab_one"
                  >
                    <div className="tab-one">
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "20%" }}>Title</td>
                            <td>
                              <input
                                type="text"
                                name="title"
                                value={product.title || ""}
                                onChange={updateDetailsHandler}
                                className="form-control mb-2"
                                placeholder="Name"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>description</td>
                            <td>
                              <textarea
                                rows={3}
                                name="description"
                                value={product.description || ""}
                                onChange={updateDetailsHandler}
                                className="form-control mb-2"
                                placeholder="Description"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Base Price</td>
                            <td>
                              <input
                                type="number"
                                name="basePrice"
                                value={product.basePrice || ""}
                                onChange={updateDetailsHandler}
                                className="form-control mb-2"
                                placeholder="Base Price"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Tags</td>
                            <td>
                              <div className="d-flex align-items-center gap-2 pb-2 flex-wrap">
                                {tags?.map((tag, index) => (
                                  <span
                                    key={tag + index}
                                    className="tags"
                                    onClick={() => {
                                      setTags((currData) => {
                                        let updatedData = [...currData];
                                        updatedData.splice(index, 1);
                                        return updatedData;
                                      });
                                    }}
                                  >
                                    {tag}
                                  </span>
                                ))}
                              </div>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  setTags((currData) => {
                                    let updatedData = [...currData];
                                    if (newTag) updatedData.push(newTag.trim());
                                    setNewTag("");
                                    return updatedData;
                                  });
                                }}
                              >
                                <input
                                  type="text"
                                  value={newTag || ""}
                                  onChange={(e) => setNewTag(e.target.value)}
                                  className="form-control mb-2"
                                  placeholder="Add new tag"
                                />
                              </form>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="custom-control d-flex gap-2">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="featured"
                                    id="featured"
                                    checked={product?.featured}
                                    onChange={(e) => {
                                      setProduct({
                                        ...product,
                                        [e.target.name]: e.target.checked,
                                      });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"featured"}
                                  >
                                    Featured
                                  </label>
                                </div>
                                <div className="custom-control d-flex gap-2">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="bestSeller"
                                    id="bestSeller"
                                    checked={product?.bestSeller}
                                    onChange={(e) => {
                                      setProduct({
                                        ...product,
                                        [e.target.name]: e.target.checked,
                                      });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"bestSeller"}
                                  >
                                    Best Seller
                                  </label>
                                </div>
                                <div className="custom-control d-flex gap-2">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="onSale"
                                    id="onSale"
                                    checked={product?.onSale}
                                    onChange={(e) => {
                                      setProduct({
                                        ...product,
                                        [e.target.name]: e.target.checked,
                                      });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={"onSale"}
                                  >
                                    On Sale
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_two" ? "show active" : ""
                    } `}
                    id="tab_two"
                  >
                    <div
                      className="tab-two"
                      style={{ overflow: "auto", maxHeight: "50vh" }}
                    >
                      <table className="table ">
                        <tbody>
                          {variants?.map((variant, ind) => (
                            <VariantRow
                              variant={variant}
                              key={variant?.name + ind}
                              index={ind}
                              setVariants={setVariants}
                            />
                          ))}
                          {showNewVariant && (
                            <NewVariantRow
                              setShowNewVariant={setShowNewVariant}
                              setVariants={setVariants}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_three" ? "show active" : ""
                    } `}
                    id="tab_three"
                  >
                    <div className="tab-three">
                      <table className="table ">
                        <tbody>
                          {filters?.map((filter, ind) => (
                            <tr key={filter?._id}>
                              <td style={{ width: "20%" }}>{filter?.name}</td>
                              <td>
                                <div className="d-flex gap-3 flex-wrap">
                                  {filter?.filterOptions?.map((option) => (
                                    <div
                                      className="custom-control "
                                      key={option?._id}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input me-2"
                                        id={option?._id}
                                        checked={selectedFilters?.includes(
                                          option?._id
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            setSelectedFilters(
                                              (currFilters) => [
                                                ...currFilters,
                                                e.target.id,
                                              ]
                                            );
                                          else
                                            setSelectedFilters((currFilters) =>
                                              currFilters.filter(
                                                (option) =>
                                                  option !== e.target.id
                                              )
                                            );
                                        }}
                                      />
                                      <label
                                        className="custom-control-label"
                                        for={option._id}
                                      >
                                        {option?.name}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_four" ? "show active" : ""
                    } `}
                    id="tab_four"
                  >
                    <div className="tab-four">
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "20%" }}>Images</td>
                            <td>
                              <div className="d-flex gap-4 flex-wrap">
                                {uploadedImageUrls?.map((url, ind) => (
                                  <ImageCard
                                    url={url}
                                    key={ind}
                                    deleteHandler={() => {
                                      setImages((currImages) => {
                                        currImages.splice(ind, 1);
                                        const urlArr = currImages?.map(
                                          (image) => URL.createObjectURL(image)
                                        );
                                        setUploadedImageUrls(urlArr);
                                        return currImages;
                                      });
                                    }}
                                  />
                                ))}
                              </div>
                              <div className="d-flex gap-4 flex-wrap ">
                                <form className="form-container">
                                  <div
                                    className="dropzone"
                                    onClick={() => imgRef?.current?.click()}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragLeave={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                      e.preventDefault();
                                      if (e.dataTransfer.files[0]) {
                                        const imgArray = Array.from(
                                          e.dataTransfer.files
                                        );
                                        setImages(imgArray);
                                        const urlArr = imgArray?.map((image) =>
                                          URL.createObjectURL(image)
                                        );
                                        setUploadedImageUrls(urlArr);
                                      }
                                    }}
                                  >
                                    Drop or Click to Upload Image
                                    <input
                                      type="file"
                                      accept=".jpg, .png, .jpeg, .gif"
                                      multiple
                                      hidden
                                      ref={imgRef}
                                      onChange={(e) => {
                                        if (e.currentTarget.files[0]) {
                                          const imgArray = Array.from(
                                            e.currentTarget.files
                                          );
                                          setImages(imgArray);
                                          const urlArr = imgArray.map((image) =>
                                            URL.createObjectURL(image)
                                          );
                                          setUploadedImageUrls(urlArr);
                                        }
                                      }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "20%" }}>Videos</td>
                            <td>
                              <div className={"d-flex gap-4 flex-wrap "}>
                                {uploadedVideoUrls?.map((url, ind) => (
                                  <VideoCard
                                    url={url}
                                    key={url}
                                    deleteHandler={() => {
                                      setVideos((currVideos) => {
                                        currVideos.splice(ind, 1);
                                        const urlArr = currVideos.map((image) =>
                                          URL.createObjectURL(image)
                                        );
                                        setUploadedVideoUrls(urlArr);
                                        return currVideos;
                                      });
                                    }}
                                  />
                                ))}
                              </div>
                              <div className="d-flex gap-4 flex-wrap">
                                <form className="form-container">
                                  <div
                                    className="dropzone"
                                    onClick={() => vdoRef?.current?.click()}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragLeave={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                      e.preventDefault();
                                      if (e.dataTransfer.files[0]) {
                                        const vdoArray = Array.from(
                                          e.dataTransfer.files
                                        );
                                        setVideos(vdoArray);
                                        const urlArr = vdoArray.map((video) =>
                                          URL.createObjectURL(video)
                                        );
                                        setUploadedVideoUrls(urlArr);
                                      }
                                    }}
                                  >
                                    Drop or Click to Upload Videos
                                    <input
                                      type="file"
                                      accept=".mp4, .webm, .ogg"
                                      multiple
                                      hidden
                                      ref={vdoRef}
                                      onChange={(e) => {
                                        if (e.currentTarget.files[0]) {
                                          const vdoArray = Array.from(
                                            e.currentTarget.files
                                          );
                                          setVideos(vdoArray);
                                          const urlArr = vdoArray.map((video) =>
                                            URL.createObjectURL(video)
                                          );
                                          setUploadedVideoUrls(urlArr);
                                        }
                                      }}
                                    />
                                  </div>
                                </form>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer" style={{ textTransform: "capitalize" }}>
        <button className="btn btn-sqr" onClick={addNewProduct}>
          Publish
        </button>
        {activeTab === "tab_two" && (
          <button
            className="btn btn-sqr"
            onClick={() => {
              setShowNewVariant(true);
            }}
            disabled={showNewVariant === true}
          >
            Add Variant
          </button>
        )}
      </div>
    </>
  );
}

export default ProductDetailsTabs;
