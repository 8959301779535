import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../Shop/Pagination";

import axios from "axios";
import { toast } from "react-toastify";

const DeletedUsers = () => {
  const { token } = useSelector((state) => state.user);
  const limit = 5;
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    totalFilterResults: 0,
    fetchedUsers: 0,
    page: 1,
    limit: 0,
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [res, setRes] = useState({
    from: 0,
    to: 0,
  });
  const [loadingStatus, setLoadingStatus] = useState("loading");

  const fetchPage = async (page) => {
    setLoadingStatus("loading");
    let url = `${process.env.REACT_APP_BACKEND_URL}/user/deleted?keyword=${
      searchKeyword || ""
    }&limit=${limit}&page=${page || 1}`;

    try {
      const response = await axios.post(
        url,
        {},
        { headers: { Authorization: token } }
      );
      let pagination = {
        totalFilterResults: response?.data?.itemCount,
        page: response?.data?.page,
        limit: response?.data?.limit,
        fetchedUsers: response?.data?.items?.length,
      };
      setPagination(pagination);
      setUsers(response?.data?.items);
      setLoadingStatus("succeeded");
    } catch (error) {
      toast.error("Something went wrong");
      setLoadingStatus("failed");
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  useEffect(() => {
    const skip = pagination?.limit * (pagination?.page - 1);
    setRes({
      from: skip + 1,
      to: pagination?.fetchedUsers + skip,
    });

    setTotalPages(
      Math.ceil(pagination?.totalFilterResults / pagination?.limit)
    );
    setActivePage(pagination?.page);
  }, [pagination]);

  return (
    <>
      <div className="myaccount-content">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5
            style={{
              border: "none",
            }}
          >
            Deleted Users
          </h5>
          <div className="header-search-container">
            <form
              className="header-search-box d-xl-block"
              onSubmit={(e) => {
                e.preventDefault();
                fetchPage();
              }}
            >
              <input
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder="Search by Name/Email"
                className="header-search-field"
              />
              <button type="submit" className="header-search-btn">
                <i className="pe-7s-search"></i>
              </button>
            </form>
          </div>
        </div>

        {loadingStatus === "loading" && (
          <div
            className="d-flex flex-column justify-content-center align-items-center gap-4"
            style={{ height: "40vh" }}
          >
            <img src="/assets/img/logo/Rolling-1s-200px.svg" />
          </div>
        )}
        {loadingStatus === "failed" && (
          <p className="saved-message">Failed to fetch users.</p>
        )}
        {loadingStatus === "succeeded" && (
          <div className="myaccount-table table-responsive text-center">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Sr. No.</th>
                  <th>Full Name</th>
                  <th>E-mail</th>
                  <th>Mobile</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, index) => {
                  return (
                    <tr key={user?._id}>
                      <td>{res.from + index}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {user?.fullName}
                      </td>
                      <td>{user?.email}</td>
                      <td>{user?.contact}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          totalPages={totalPages}
          activePage={activePage}
          fetchPage={fetchPage}
        />
      </div>
    </>
  );
};

export default DeletedUsers;
