import React, { useEffect, useState } from "react";
import FilterTabs from "./FilterTabs";
import { toast } from "react-toastify";

const FilterModal = ({ showModal, closeModal, val, fun }) => {
  const [filters, setFilter] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/filter`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setFilter(res);
      })
      .catch((err) => {
        toast.error("Failed to fetch filter");
      });
  }, []);

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"} d-lg-none`}
        id="filterModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <>
                  <h5 className="modal-title">Filters</h5>
                </>
              </div>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <FilterTabs
              filters={filters}
              priceRange={val.priceRange}
              setPriceFilter={fun?.setPriceFilter}
              selectedFilters={val.selectedFilters}
              priceFilter={val.priceFilter}
              setSelectedFilters={fun?.setSelectedFilters}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterModal;
