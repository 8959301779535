import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { specialProduct } from "../../redux/ProductSlice";
import { Link } from "react-router-dom";
import pathToUrl from "../PathToUrl";
import { IconContext } from "react-icons";
import { AiOutlineShop } from "react-icons/ai";

const ProductItem = ({ imageUrls, title, basePrice, slug }) => {
  return (
    <div
      className="group-item"
      style={{ maxHeight: "60px", marginBottom: "10px", overflow: "hidden" }}
    >
      <div className="group-item-thumb d-flex justify-content-center align-items-center ">
        <Link to={`/${slug}`}>
          {imageUrls.length && <img src={pathToUrl(imageUrls[0])} alt="" />}
        </Link>
      </div>
      <div className="group-item-desc">
        <h5 className="group-product-name" style={{ padding: "0" }}>
          <Link to={`/${slug}`}>{title}</Link>
        </h5>
        <div className="price-box">
          <span className="price-regular">₹{basePrice}</span>
        </div>
      </div>
    </div>
  );
};

const ProductGroup = ({ products, title }) => {
  const settings = {
    infinite: true,
    rows: 4,
    autoplay: true,
    pauseOnHover: true,
    prevArrow: (
      <button type="button" className="slick-prev">
        <i className="pe-7s-angle-left"></i>
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        <i className="pe-7s-angle-right"></i>
      </button>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  $(".group-list-carousel").each(function () {
    var $this = $(this);
    if ($this.slick) {
      var $arrowContainer = $(this)
        .parent()
        .siblings(".section-title-append")
        .find(".slick-append");

      $this?.slick({
        ...settings,
        appendArrows: $arrowContainer,
      });
    }
  });

  return (
    <div className="categories-group-wrapper" style={{ height: "100%" }}>
      <div className="section-title-append">
        <h4>{title}</h4>
        <div className="slick-append"></div>
      </div>

      <div className="group-list-item-wrapper" style={{ height: "100%" }}>
        {products?.length > 0 ? (
          <Slider {...settings} className="group-list-carousel">
            {products?.map((product, index) => (
              <ProductItem {...product} key={index} />
            ))}
          </Slider>
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center gap-4 "
            style={{ height: "80%" }}
          >
            <IconContext.Provider value={{ color: "#C29958" }}>
              <AiOutlineShop size={40} />
            </IconContext.Provider>
            <p>Coming Soon...</p>
          </div>
        )}
      </div>
    </div>
  );
};

const GroupProductArea = () => {
  const dispatch = useDispatch();
  const { onSale, bestSeller, data } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(specialProduct("onSale"));
    dispatch(specialProduct("bestSeller"));
  }, []);

  return (
    <section className="group-product-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="group-product-banner">
              <figure className="banner-statistics">
                <a href="#">
                  <img
                    src="assets/img/banner/img-bottom-banner.jpg"
                    alt="product banner"
                  />
                </a>
                <div className="banner-content banner-content_style3 text-center">
                  <h6 className="banner-text1">BEAUTIFUL</h6>
                  <h2 className="banner-text2">Wedding Rings</h2>
                  <a href="shop.html" className="btn btn-text">
                    Shop Now
                  </a>
                </div>
              </figure>
            </div>
          </div>
          <div className="col-lg-3">
            <ProductGroup products={bestSeller} title="Best Seller Products" />
          </div>
          <div className="col-lg-3">
            <ProductGroup products={onSale} title="On-Sale Products" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GroupProductArea;
