import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToWishlist } from "../../redux/WishlistSlice";
import { useEffect } from "react";
import { addToCart } from "../../redux/CartSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import pathToUrl from "../PathToUrl";

const ProductDetails = ({ product }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { imageUrls } = product;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const initialOptions = [];

    product?.variants?.map(
      (variant, index) =>
        (initialOptions[index] = {
          variantType: variant?._id,
          selectedOption: variant?.options && variant?.options[0]._id,
          incRate: variant?.options && variant?.options[0].incRate,
        })
    );
    setSelectedOptions(initialOptions);
  }, []);

  useEffect(() => {
    const initialPrice =
      product?.basePrice +
      selectedOptions.reduce((acc, option) => {
        return acc + option.incRate;
      }, 0);

    setPrice(initialPrice);
  }, [selectedOptions, product]);

  const handleOptionChange = (variantIndex, optionIndex) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[variantIndex] = {
      variantType: product?.variants[variantIndex]?._id,
      selectedOption:
        product?.variants[variantIndex]?.options[optionIndex]?._id,
      incRate: product?.variants[variantIndex]?.options[optionIndex]?.incRate,
    };
    setSelectedOptions(updatedOptions);
  };

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
    }
  };
  const addItem = async () => {
    if (!token) return toast.error("Login to add item in wishlist");
    try {
      await toast.promise(
        dispatch(addToWishlist({ token, id: product?._id })),
        {
          pending: "Adding to Wishlist...",
          success: "Item added to Wishlist!",
          error: "Failed to add item to Wishlist.",
        }
      );
    } catch (error) {
      toast.error("Failed to add item to Wishlist");
    }
  };

  const addToCartHandler = async () => {
    if (!token) return toast.error("Login to add item in cart");
    const itemDetail = {
      productId: product._id,
      options: selectedOptions,
      quantity,
    };

    try {
      await toast.promise(dispatch(addToCart({ token, itemDetail })), {
        pending: "Adding to Cart...",
        success: "Item added to Cart!",
        error: "Failed to add item to Cart.",
      });
    } catch (error) {
      toast.error("Failed to add item to Cart.");
    }
  };

  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: window.location.href,
    });
  };

  const shareOnWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        window.location.href
      )}`
    );
  };

  const shareOnTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        "Check out this link: " + window.location.href
      )}`
    );
  };

  const shareOnPinterest = () => {
    const mediaUrl = encodeURIComponent(
      pathToUrl(imageUrls?.length && imageUrls[0])
    );

    window.open(
      `https://www.pinterest.com/pin/create/button?media=${mediaUrl}`,
      "_blank"
    );
  };

  return (
    <>
      <div className="product-details-inner">
        <div className="row">
          <div className="col-lg-5">
            <div className="swiper-container">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#c29958",
                  "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
              >
                {imageUrls?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="pro-large-img img-zoom "
                      style={{ height: "100%" }}
                    >
                      <div
                        className="zoomed-image-container  d-flex justify-content-center align-items-center"
                        style={{ height: "60vh" }}
                      >
                        <img
                          className="zoomed-image"
                          src={pathToUrl(image)}
                          alt={`product-large-${index}`}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="pro-nav"
              >
                {imageUrls?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="pro-nav-thumb"
                      style={{
                        backgroundImage: `url(${pathToUrl(image)})`,
                        height: "100px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="product-details-des">
              <div className="manufacturer-name">
                <a href="product-details.html">{product?.manufacturer}</a>
              </div>
              <h3 className="product-name">{product?.title}</h3>
              <div className="price-box">
                <span className="price-regular">₹{price?.toFixed(2)}</span>
                {product.oldPrice && (
                  <span className="price-old">
                    <del>₹{product.oldPrice?.toFixed(2)}</del>
                  </span>
                )}
              </div>
              <p className="pro-desc">{product?.description}</p>
              <div className="quantity-cart-box d-flex align-items-center">
                <h6 className="option-title">qty:</h6>
                <div className="quantity">
                  <div className="pro-qty">
                    <span
                      className="dec qtybtn"
                      onClick={() => handleQuantityChange(quantity - 1)}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      value={quantity}
                      onChange={(e) =>
                        handleQuantityChange(parseInt(e.target.value) || 0)
                      }
                    />
                    <span
                      className="inc qtybtn"
                      onClick={() => handleQuantityChange(quantity + 1)}
                    >
                      +
                    </span>
                  </div>
                </div>
                <div className="action_link">
                  <button className="btn btn-cart2" onClick={addToCartHandler}>
                    Add to cart
                  </button>
                </div>
              </div>
              {product?.variants?.map((variant, variantIndex) => (
                <div className="pro-size" key={variant._id}>
                  <h6 className="option-title">{variant.type}:</h6>
                  <select
                    onChange={(e) =>
                      handleOptionChange(variantIndex, e.target.selectedIndex)
                    }
                    className="nice-select"
                  >
                    {variant?.options?.map((option) => (
                      <option
                        key={option?._id}
                        value={option?.incRate}
                        disabled={!option?.inStock || !option.isActive}
                      >
                        {option?.name}
                      </option>
                    ))}
                  </select>
                  <br />
                </div>
              ))}

              <div className="useful-links">
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  title="Wishlist"
                  onClick={() => addItem()}
                >
                  <i className="pe-7s-like"></i>wishlist
                </a>
              </div>
              <div className="like-icon">
                <a
                  className="facebook"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnFacebook();
                  }}
                >
                  <i className="fa fa-facebook"></i>like
                </a>
                <a
                  className="twitter"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnTwitter();
                  }}
                >
                  <i className="fa fa-twitter"></i>tweet
                </a>
                <a
                  className="whatsapp"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnWhatsApp();
                  }}
                >
                  <i className="fa fa-whatsapp"></i>share
                </a>
                <a
                  className="pinterest"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    shareOnPinterest();
                  }}
                >
                  <i className="fa fa-pinterest"></i>save
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
