import React, { useLayoutEffect } from "react";
import BannerStatisticsArea from "../components/Home/BannerStatisticsArea";
import HeroSlider from "../components/Home/HeroSlider";
import ProductSection from "../components/Home/ProductSection";
import ProductBanner from "../components/Home/ProductBanner";
import FeaturedProducts from "../components/Home/FeaturedProducts";
import TestimonialSection from "../components/Home/Testimonials";
import GroupProductArea from "../components/Home/GroupProductArea";
import BrandLogoCarousel from "../components/Home/BrandCarousel";

const Home = () => {
  const brandImages = [
    "assets/img/brand/1.png",
    "assets/img/brand/2.png",
    "assets/img/brand/3.png",
    "assets/img/brand/4.png",
    "assets/img/brand/5.png",
    "assets/img/brand/6.png",
  ];

  return (
    <main>
      <HeroSlider />
      <div className="service-policy section-padding">
        <div className="container">
          <div className="row mtn-30">
            <div className="col-sm-6 col-lg-3">
              <div className="policy-item">
                <div className="policy-icon">
                  <i className="pe-7s-plane"></i>
                </div>
                <div className="policy-content">
                  <h6>Free Shipping</h6>
                  <p>Free shipping all order</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="policy-item">
                <div className="policy-icon">
                  <i className="pe-7s-help2"></i>
                </div>
                <div className="policy-content">
                  <h6>Support 24/7</h6>
                  <p>Support 24 hours a day</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="policy-item">
                <div className="policy-icon">
                  <i className="pe-7s-back"></i>
                </div>
                <div className="policy-content">
                  <h6>Money Return</h6>
                  <p>30 days for free return</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="policy-item">
                <div className="policy-icon">
                  <i className="pe-7s-credit"></i>
                </div>
                <div className="policy-content">
                  <h6>100% Payment Secure</h6>
                  <p>We ensure secure payment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BannerStatisticsArea />

      <ProductSection />

      <ProductBanner />

      <FeaturedProducts />

      <TestimonialSection />

      <GroupProductArea />

      <BrandLogoCarousel brandImages={brandImages} />
    </main>
  );
};

export default Home;
