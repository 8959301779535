import React from "react";
import CartItem from "./CartItem";

const CartTable = ({ cartItems, adminView }) => {
  return (
    <div className="cart-table table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="pro-thumbnail">Thumbnail</th>
            <th className="pro-title">Product</th>
            <th className="pro-price">Price</th>
            <th className="pro-quantity">Quantity</th>
            <th className="pro-subtotal">Total</th>
            {!adminView && <th className="pro-remove">Remove</th>}
          </tr>
        </thead>
        <tbody>
          {cartItems?.map((item) => (
            <CartItem key={item._id} item={item} adminView={adminView} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CartTable;
