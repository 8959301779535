import React, { useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { fetchWishlistItems, removeFromWishlist } from "../redux/WishlistSlice";
import {} from "../redux/CartSlice";
import { IconContext } from "react-icons";
import { BsFillBagHeartFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pathToUrl from "../components/PathToUrl";

const Wishlist = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const wishlistItems = useSelector((state) => state.wishlist.items);

  useEffect(() => {
    dispatch(fetchWishlistItems(token));
  }, [dispatch, token]);

  const handleRemove = async (itemId) => {
    try {
      await toast.promise(dispatch(removeFromWishlist({ token, id: itemId })), {
        pending: "Removing item...",
        success: "Item removed successfully!",
        error: "Failed to remove item.",
      });
    } catch (error) {
      toast.error("Failed removing item. Please try again.");
    }
  };

  return (
    <>
      <Breadcrumb />
      {wishlistItems?.length > 0 ? (
        <div className="wishlist-main-wrapper section-padding">
          <div className="container">
            <div className="section-bg-color">
              <div className="row">
                <div className="col-lg-12">
                  <div className="cart-table table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="pro-thumbnail">Thumbnail</th>
                          <th className="pro-title">Product</th>
                          <th className="pro-price">Price</th>
                          <th className="pro-remove">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wishlistItems?.map((item) => (
                          <tr key={item._id}>
                            <td className="pro-thumbnail">
                              <img
                                className="img-fluid"
                                src={
                                  item.imageUrls &&
                                  pathToUrl(item?.imageUrls[0])
                                }
                                alt={item.title}
                              />
                            </td>
                            <td className="pro-title">
                              <Link to={`/${item.slug}`}>{item.title}</Link>
                            </td>
                            <td className="pro-price">₹{item.basePrice}</td>
                            <td className="pro-remove">
                              <button onClick={() => handleRemove(item._id)}>
                                <i className="fa fa-trash-o"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "80vh" }}
        >
          <IconContext.Provider value={{ color: "#C29958" }}>
            <BsFillBagHeartFill size={100} />
          </IconContext.Provider>
          <p>
            Nothing in Wishlist. Please select from the{" "}
            <Link to="/shop" style={{ color: "#C29958" }}>
              Shop
            </Link>
            .
          </p>
        </div>
      )}
    </>
  );
};

export default Wishlist;
