import axios from "axios";
import React, { useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../redux/UserSlice";
import { toast } from "react-toastify";
// import { login } from "../redux/UserSlice";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useParams();
  const [resetData, setResetData] = useState({
    password: "",
    repeatPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setResetData({ ...resetData, [name]: inputValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      resetData.password.length < 8 ||
      resetData.password !== resetData.repeatPassword
    ) {
      toast.error("Please enter a valid password");
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/user/reset/${token}`, {
          password: "",
        })
        .then((res) => {
          if (res.data.token) {
            dispatch(
              login({
                auth: true,
                name: res.data.name,
                role: res.data.role,
                token: res.data.token,
              })
            );
            navigate("/shop");
          } else {
            toast.error("Failed. Please try again.");
          }
        })
        .catch((err) => toast.error("Failed. Please try again."));
    }
  };

  return (
    <>
      <div className="login-register-wrapper section-padding">
        <div className="container">
          <div className="member-area-from-wrap d-flex justify-content-center">
            <div className="row">
              <div className="col-lg-6">
                <div className="login-reg-form-wrap">
                  <h5>Reset Password</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="single-input-item">
                      <input
                        type="password"
                        name="password"
                        placeholder="New Password"
                        value={resetData.password}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="single-input-item">
                      <input
                        type="password"
                        name="repeatPassword"
                        placeholder="Confirm Password"
                        value={resetData.repeatPassword}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="single-input-item">
                      <button type="submit" className="btn btn-sqr">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
