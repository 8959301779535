import React from "react";
import ProductSlider from "../ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { specialProduct } from "../../redux/ProductSlice";
import { IconContext } from "react-icons";
import { AiOutlineShop } from "react-icons/ai";

const FeaturedProducts = () => {
  const dispatch = useDispatch();
  const { featured } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(specialProduct("featured"));
  }, []);
  return (
    <section className="feature-product section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <h2 className="title">featured products</h2>
              <p className="sub-title">
                Add featured products to the weekly lineup
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {featured?.length ? (
            <div className="col-12">
              <ProductSlider products={featured} />
            </div>
          ) : (
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-4 "
              style={{ height: "80%" }}
            >
              <IconContext.Provider value={{ color: "#C29958" }}>
                <AiOutlineShop size={40} />
              </IconContext.Provider>
              <p>Coming Soon...</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeaturedProducts;
