import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Breadcrumb from "../components/Breadcrumb";

const Map = () => {
  return (
    <div className="map-area section-padding">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d222.34162037170455!2d75.8242273!3d26.9204399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db6b4a702ff41%3A0xc5280c14504e764f!2s1226%2C%20Achar%20Walon%20Ki%20Gali%2C%20Bapu%20Bazar%2C%20Pink%20City%2C%20Jaipur%2C%20Rajasthan%20302002!5e0!3m2!1sen!2sin!4v1698755975080!5m2!1sen!2sin"
        style={{ border: 0, width: "100%", height: "60vh" }}
        allowFullScreen=""
        loading="lazy"
        className="google-map"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

const ContactForm = () => {
  return (
    <div className="contact-message">
      <h4 className="contact-title">Tell Us Your Project</h4>
      <form
        id="contact-form"
        action="https://whizthemes.com/mail-php/genger/mail.php"
        method="post"
        className="contact-form"
      >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <input
              name="first_name"
              placeholder="Name *"
              type="text"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <input name="phone" placeholder="Phone *" type="text" required />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <input
              name="email_address"
              placeholder="Email *"
              type="text"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <input name="contact_subject" placeholder="Subject" type="text" />
          </div>
          <div className="col-12">
            <div className="contact2-textarea text-center">
              <textarea
                placeholder="Message *"
                name="message"
                className="form-control2"
                required=""
              ></textarea>
            </div>
            <div className="contact-btn">
              <button className="btn btn-sqr" type="submit">
                Send Message
              </button>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="form-messege"></p>
          </div>
        </div>
      </form>
    </div>
  );
};

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <h4 className="contact-title">Contact Us</h4>
      <p>
        Claritas est etiam processus dynamicus, qui sequitur mutationem
        consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc
        putamus parum claram anteposuerit litterarum formas human.
      </p>
      <ul>
        <li>
          <i className="fa fa-fax"></i> Address: No 40 Baria Street 133/2 New
          York City
        </li>
        <li>
          <i className="fa fa-phone"></i> E-mail: info@yourdomain.com
        </li>
        <li>
          <i className="fa fa-envelope-o"></i> +88013245657
        </li>
      </ul>
      <div className="working-time">
        <h6>Working Hours</h6>
        <p>
          <span>Monday – Saturday:</span> 08AM – 22PM
        </p>
      </div>
    </div>
  );
};

const ContactPage = () => {
  return (
    <main>
      <Breadcrumb />
      <Map />
      <div className="contact-area section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <ContactForm />
            </div>
            <div className="col-lg-6">
              <ContactInfo />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContactPage;
