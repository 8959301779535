import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="main-container error404">
      <div className="container">
        <div className="search-form-wrapper">
          <h2>OOPS! PAGE NOT BE FOUND</h2>
          <p className="home-link">
            Sorry but the page you are looking for does not exist, has been
            removed, changed or is temporarity unavailable.
          </p>
          <Link className="button" to="/">
            Back to home page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
