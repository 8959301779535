import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import CartTable from "../components/Cart/CartTable";
import CartCalculator from "../components/Cart/CartCalculator";
import { useSelector, useDispatch } from "react-redux";
import { BsFillCartXFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { fetchCartItems } from "../redux/CartSlice";

const Cart = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { items } = useSelector((state) => state.cart);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(fetchCartItems(token));
  }, []);

  useEffect(() => {
    const totalPrice = items.reduce((acc, item) => acc + item.totalPrice, 0);
    setTotal(totalPrice);
  }, [items]);

  return (
    <main>
      <Breadcrumb text={"Cart"} />
      {items?.length > 0 ? (
        <div className="cart-main-wrapper section-padding">
          <div className="container">
            <div className="section-bg-color">
              <div className="row">
                <div className="col-lg-12">
                  <CartTable cartItems={items} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 ml-auto">
                  <CartCalculator total={total} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "80vh" }}
        >
          <IconContext.Provider value={{ color: "#C29958" }}>
            <BsFillCartXFill size={100} />
          </IconContext.Provider>
          <p>
            Nothing in Cart. Please select from the{" "}
            <Link to="/shop" style={{ color: "#C29958" }}>
              Shop
            </Link>
            .
          </p>
        </div>
      )}
    </main>
  );
};

export default Cart;
