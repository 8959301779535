import React, { useRef, useEffect } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui.css";
import { useState } from "react";

const SidebarFilter = ({
  title,
  filterOptions,
  setSelectedFilters,
  selectedFilters,
}) => {
  const handleCheckboxChange = (e, filterId) => {
    const isChecked = e.target.checked;
    setSelectedFilters((prevFilters) => {
      if (isChecked) {
        return [...prevFilters, filterId];
      } else {
        return prevFilters.filter((id) => id !== filterId);
      }
    });
  };

  return (
    <div className="sidebar-single">
      <h5 className="sidebar-title">{title}</h5>
      <div className="sidebar-body">
        <ul className="checkbox-container categories-list">
          {filterOptions?.map((option, index) => (
            <li key={option?._id}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name={title}
                  value={option?._id}
                  onChange={(e) => handleCheckboxChange(e, option?._id)}
                  className="custom-control-input"
                  checked={selectedFilters.includes(option?._id)}
                  id={`customCheck-${title.split(" ")[0]}-${index}`}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customCheck-${title.split(" ")[0]}-${index}`}
                >
                  {option.name}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SidebarBanner = () => {
  return (
    <div className="sidebar-banner">
      <div className="img-container">
        <a href="#">
          <img src="assets/img/banner/sidebar-banner.jpg" alt="" />
        </a>
      </div>
    </div>
  );
};

const SidebarPrice = ({ priceRange, priceFilter, setPriceFilter }) => {
  const sliderRef = useRef(null);
  useEffect(() => {
    $("#amount").val("₹" + priceFilter?.min + " - ₹" + priceFilter?.max);
    $(sliderRef.current).slider({
      range: true,
      min: priceRange.min,
      max: priceRange.max,
      values: [priceFilter?.min, priceFilter?.max],
      slide: function (event, ui) {
        $("#amount").val("₹" + ui.values[0] + " - ₹" + ui.values[1]);
      },
    });
  }, [priceFilter]);

  const handleFilter = () => {
    const [minPrice, maxPrice] = $(sliderRef.current).slider("values");
    setPriceFilter({ min: minPrice, max: maxPrice });
  };

  return (
    <div className="sidebar-single">
      <h5 className="sidebar-title">price</h5>
      <div className="sidebar-body">
        <div className="price-range-wrap">
          <div ref={sliderRef} className="price-range"></div>
          <div className="range-slider">
            <form className="d-flex flex-column ">
              <div className="price-input">
                <label htmlFor="amount">Price: </label>
                <input
                  type="text"
                  id="amount"
                  readOnly
                  style={{ maxWidth: "none" }}
                />
              </div>
              <button
                type="button"
                className="filter-btn"
                onClick={handleFilter}
                style={{ alignSelf: "flex-end" }}
              >
                Apply
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = ({ val, fun, className }) => {
  const [filters, setFilter] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/filter`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setFilter(res);
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);

  return (
    <div className={`col-lg-3 order-2 order-lg-1 ${className}`}>
      <aside className="sidebar-wrapper">
        <SidebarPrice
          priceRange={val.priceRange}
          priceFilter={val.priceFilter}
          setPriceFilter={fun.setPriceFilter}
        />
        {filters?.map((filter) => (
          <SidebarFilter
            key={filter._id}
            title={filter?.name}
            filterOptions={filter.filterOptions}
            selectedFilters={val.selectedFilters}
            setSelectedFilters={fun.setSelectedFilters}
          />
        ))}
        <SidebarBanner />
      </aside>
    </div>
  );
};

export default Sidebar;
