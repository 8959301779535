import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { logout } from "../redux/UserSlice";
import { clearCart } from "../redux/CartSlice";
import { clearWishlist } from "../redux/WishlistSlice";
import Orders from "../components/My Account/Orders";
import AddressEdit from "../components/My Account/Addresses";
import Dashboard from "../components/My Account/Dashboard";
import PaymentMethod from "../components/My Account/PaymentMethods";
import ProductFilters from "../components/My Account/ProductFilters";
import Products from "../components/My Account/Products/Products";
import Users from "../components/My Account/Users/Users";
import DeletedUsers from "../components/My Account/DeletedUsers";
import { toast } from "react-toastify";

const MyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, name, token, role } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [user, setUser] = useState({
    fullName: name,
    email: "erik.jhonson@example.com",
  });
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState("all");
  const [addresses, setAddresses] = useState([]);
  const [orderLoadingStatus, setOrderLoadingStatus] = useState("loading");
  const [addressesLoadingStatus, setAddressesLoadingStatus] =
    useState("loading");

  useEffect(() => {
    if (!auth) {
      return navigate("/");
    }
    setAddressesLoadingStatus("loading");
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/addresses`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setAddressesLoadingStatus("succeeded");
        setAddresses(res.data);
      })
      .catch((err) => {
        setAddressesLoadingStatus("failed");
        toast.error("Failed to fetch saved addresses.");
      });
  }, []);

  useEffect(() => {
    setOrderLoadingStatus("loading");
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/order?status=${status}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setOrders(res.data);
        setOrderLoadingStatus("succeeded");
      })
      .catch((err) => {
        setOrderLoadingStatus("failed");
        toast.error("Failed to fetch orders.");
      });
  }, [status]);

  const updateStatus = (id, status) => {
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_URL}/order/${id}`,
      headers: {
        Authorization: token,
      },
      data: { status },
    };

    axios
      .request(config)
      .then((res) => {
        const index = orders.findIndex((order) => order?._id === id);

        if (index !== -1) {
          const updatedOrders = [...orders];
          updatedOrders[index] = res.data;

          setOrders(updatedOrders);
          toast.success("Order status updated successfully!");
        }
      })
      .catch((error) => {
        toast.error("Failed to update order status. Please try again.");
      });
  };

  const deleteOrder = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/order/${id}`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res?.status === 200) {
          setOrders((currOrder) =>
            currOrder?.filter((order) => order?._id !== id)
          );
          toast.success("Order deleted successfully!");
        }
      })
      .catch((error) => {
        toast.error("Failed to delete order. Please try again.");
      });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "dashboard":
        return <Dashboard user={user} />;
      case "orders":
        return (
          <Orders
            orders={orders}
            token={token}
            status={status}
            setStatus={setStatus}
            updateStatus={updateStatus}
            deleteOrder={deleteOrder}
            loadingStatus={orderLoadingStatus}
          />
        );
      case "payment-method":
        return <PaymentMethod />;
      case "address-edit":
        return (
          <AddressEdit
            addresses={addresses}
            token={token}
            setAddresses={setAddresses}
            loadingStatus={addressesLoadingStatus}
          />
        );
      case "filters":
        return <ProductFilters user={user} />;
      case "products":
        return <Products />;
      case "users":
        return <Users />;
      case "deletedusers":
        return <DeletedUsers />;
      default:
        return null;
    }
  };

  return (
    <>
      <Breadcrumb />
      <div className="my-account-wrapper section-padding">
        <div className="container">
          <div className="section-bg-color">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="myaccount-tab-menu nav" role="tablist">
                  <a
                    href="#dashboard"
                    className={activeTab === "dashboard" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("dashboard");
                    }}
                  >
                    Dashboard
                  </a>
                  <a
                    href="#orders"
                    className={activeTab === "orders" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("orders");
                    }}
                  >
                    {role === "admin" ? "All orders" : "My Orders"}
                  </a>
                  {role === "admin" && (
                    <>
                      <a
                        href="#filters"
                        className={activeTab === "filters" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("filters");
                        }}
                      >
                        Product Filters
                      </a>
                      <a
                        href="#products"
                        className={activeTab === "products" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("products");
                        }}
                      >
                        Products
                      </a>
                      <a
                        href="#users"
                        className={activeTab === "users" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("users");
                        }}
                      >
                        Users
                      </a>
                      <a
                        href="#deltedusers"
                        className={activeTab === "deletedusers" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("deletedusers");
                        }}
                      >
                        Deleted Users
                      </a>
                    </>
                  )}
                  {role === "customer" && (
                    <>
                      <a
                        href="#address-edit"
                        className={activeTab === "address-edit" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("address-edit");
                        }}
                      >
                        My Address
                      </a>
                      <a
                        href="#payment-method"
                        className={
                          activeTab === "payment-method" ? "active" : ""
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("payment-method");
                        }}
                      >
                        Payment Method
                      </a>
                    </>
                  )}
                  <Link
                    onClick={() => {
                      dispatch(logout());
                      dispatch(clearCart());
                      dispatch(clearWishlist());
                    }}
                    to={"/login"}
                  >
                    Logout
                  </Link>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="tab-content" id="myaccountContent">
                  {renderTabContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
