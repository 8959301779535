import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import pathToUrl from "../PathToUrl";

const dateFormatter = (inputDate) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(inputDate);
};

const OrderDetailModal = ({
  showModal,
  closeModal,
  order,
  updateStatus,
  deleteOrder,
}) => {
  const { role } = useSelector((state) => state.user);
  const [status, setStatus] = useState(order?.status);
  return (
    <div
      className={`modal ${showModal ? "show" : "hide"}`}
      id="orderDetailModal"
    >
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title">Order Details</h5>
            <button type="button" className="close" onClick={closeModal}>
              &times;
            </button>
          </div>
          {order && (
            <div className="modal-body">
              <div className="d-flex justify-content-between">
                <address>
                  <h6 style={{ fontWeight: "bold" }}>Shipping Address:</h6>
                  <p>
                    <strong>{order?.shippingAddress?.fullName}</strong>
                  </p>
                  <p>
                    {order?.shippingAddress?.line1}
                    {order?.shippingAddress?.line2 &&
                      `, ${order?.shippingAddress?.line2}`}
                    <br />
                    {order?.shippingAddress?.city},{" "}
                    {order?.shippingAddress?.state} <br />
                    {order?.shippingAddress?.postalCode},{" "}
                    {order?.shippingAddress?.country}
                  </p>
                </address>
                {role === "admin" ? (
                  <select
                    className="nice-select"
                    name="status"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="processing">Processing </option>
                    <option value="shipped">Shipped</option>
                    <option value="delivered">Delivered</option>
                    <option value="rejected">Rejected</option>
                  </select>
                ) : (
                  <span
                    style={{
                      padding: "4px 20px",
                      textTransform: "capitalize",
                      height: "35px",
                      borderRadius: "20px",
                      color: "white",
                    }}
                    className={order?.status}
                  >
                    {order?.status}
                  </span>
                )}
              </div>
              <div className="cart-table table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="pro-thumbnail">Thumbnail</th>
                      <th className="pro-title">Product</th>
                      <th className="pro-price">Price</th>
                      <th className="pro-quantity">Quantity</th>
                      <th className="pro-subtotal">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.items?.map((item, index) => {
                      return (
                        <tr key={item?._id || index}>
                          <td className="pro-thumbnail">
                            <a href="#">
                              <img
                                className="img-fluid"
                                src={
                                  item?.product?.imageUrls &&
                                  pathToUrl(item?.product?.imageUrls[0])
                                }
                                alt={item?.product?.title}
                              />
                            </a>
                          </td>
                          <td className="pro-title">
                            <Link to={`/${item?.product?.slug}`}>
                              {item?.product?.title} -{" "}
                              {item?.options?.reduce(
                                (acc, option, ind) =>
                                  acc +
                                  option.selectedOption.name +
                                  (ind !== item?.options?.length - 1
                                    ? ", "
                                    : ""),
                                ""
                              )}
                            </Link>
                          </td>
                          <td className="pro-price">
                            <span>₹{item?.totalPrice / item?.quantity}</span>
                          </td>
                          <td className="pro-quantity">
                            <div className="pro-qty">
                              <input
                                type="text"
                                value={item?.quantity}
                                readOnly={true}
                              />
                            </div>
                          </td>
                          <td className="pro-subtotal">
                            <span>₹{item?.totalPrice}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className="modal-footer" style={{ textTransform: "capitalize" }}>
            <span style={{ marginRight: "auto" }}>
              <h6 style={{ fontWeight: "bold" }}>Total Amount:</h6> ₹
              {order?.totalPrice}
            </span>
            {order?.status === "pending" && role !== "admin" && (
              <button
                className="btn btn-sqr"
                onClick={() => {
                  updateStatus(order?._id, "cancelled");
                  closeModal();
                }}
              >
                Cancel Order
              </button>
            )}
            {order?.status !== "cancelled" && role === "admin" && (
              <button
                className="btn btn-sqr"
                onClick={() => {
                  updateStatus(order?._id, status);
                  closeModal();
                }}
                disabled={order?.status === status && true}
              >
                Update Order
              </button>
            )}
            {role === "admin" && (
              <button
                className="btn btn-sqr"
                onClick={() => {
                  deleteOrder(order?._id);
                  closeModal();
                }}
              >
                Delete Order
              </button>
            )}
            {/* {order?.status === "delivered" && (
                <button className="btn btn-sqr">Download Invoice</button>
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

const Orders = ({
  orders,
  token,
  status,
  setStatus,
  updateStatus,
  deleteOrder,
  loadingStatus,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [order, setOrder] = useState({
    items: [],
    shippingAddress: "",
  });

  const openModal = (orderDetails) => {
    setOrder(orderDetails);
    setShowModal(true);
  };

  const closeModal = () => {
    setOrder(null);
    setShowModal(false);
  };

  const fetchOrder = (orderId) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/order/${orderId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        openModal(res.data && res.data[0]);
      })
      .catch((err) => toast.error("Failed to fetch order"));
  };

  return (
    <>
      <OrderDetailModal
        showModal={showModal}
        closeModal={closeModal}
        order={order}
        updateStatus={updateStatus}
        deleteOrder={deleteOrder}
      />
      <div className="myaccount-content">
        <div className="d-flex justify-content-between ">
          <h5 style={{ border: "none" }}>Orders</h5>
          <div className="col-lg-5 col-md-6 order-1 order-md-2">
            <div className="top-bar-right">
              <div className="product-short " style={{}}>
                <p
                  style={{
                    margin: "0 1rem",
                  }}
                >
                  Filter By :
                </p>
                <select
                  className="nice-select"
                  name="status"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="pending">Pending </option>
                  <option value="processing">Processing </option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {loadingStatus === "loading" && (
          <div
            className="d-flex flex-column justify-content-center align-items-center gap-4"
            style={{ height: "40vh" }}
          >
            <img src="/assets/img/logo/Rolling-1s-200px.svg" />
          </div>
        )}
        {loadingStatus === "failed" && (
          <p className="saved-message">Failed to fetch orders.</p>
        )}
        {loadingStatus === "succeeded" && (
          <div className="myaccount-table table-responsive text-center">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th>Payment Method</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders?.map((order, index) => {
                  if (status !== "all" && order?.status !== status)
                    return <></>;

                  const orderDate = new Date(order?.orderDate);
                  const formattedDate = dateFormatter(orderDate);
                  // const orderStatus = order?.status.toLowerCase();
                  return (
                    <tr key={order?._id}>
                      <td>{index + 1}</td>
                      <td>{formattedDate}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {order?.status}
                      </td>
                      <td>₹{order?.totalPrice}</td>
                      <td>{order?.paymentMethod}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        <Link
                          onClick={() => {
                            // updateStatus(order._id);
                            fetchOrder(order?._id);
                          }}
                          className="btn btn-sqr"
                        >
                          View Order
                        </Link>
                        {/* )} */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default Orders;
