import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const HeroSlider = () => {
  const sliderSettings = {
    dots: false,
    fade: true,
    // arrows: false,
    infinite: true,
    prevArrow: <button type="button" className="slick-prev"></button>,
    nextArrow: <button type="button" className="slick-next"></button>,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const sliderItems = [
    {
      imgSrc: "assets/img/slider/home1-slide2.jpg",
      title: "Family Jewelry",
      subTitle: "Designer Jewelry Necklaces-Bracelets-Earings",
    },
    {
      imgSrc: "assets/img/slider/home1-slide3.jpg",
      title: "Diamonds Jewelry",
      subTitle: "Shukra Yogam & Silver Power Silver Saving Schemes.",
      floatEnd: true,
    },
    {
      imgSrc: "assets/img/slider/home1-slide1.jpg",
      title: "Grace Designer",
      subTitle: "Rings, Occasion Pieces, Pandora & More.",
    },
  ];

  return (
    <section className="slider-area">
      <Slider
        {...sliderSettings}
        className="hero-slider-active slick-arrow-style slick-arrow-style_hero slick-dot-style "
      >
        {sliderItems?.map((item, index) => (
          <div className="hero-single-slide hero-overlay " key={index}>
            <div
              className="hero-slider-item "
              style={{
                backgroundImage: `url(${item.imgSrc})`,
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className={`hero-slider-content slide-${index + 1} ${
                        item.floatEnd && "float-md-end float-none"
                      }`}
                    >
                      <h2 className="slide-title">
                        {item.title} <span>Jewelry</span>
                      </h2>
                      <h4 className="slide-desc">{item.subTitle}</h4>
                      <Link to="/shop" className="btn btn-hero">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default HeroSlider;
