import React from "react";

const Pagination = ({ totalPages, activePage, fetchPage }) => {
  const generatePageNumbers = () => {
    const visiblePageRange = 5;
    let start = Math.max(1, +activePage - Math.floor(visiblePageRange / 2));
    let end = Math.min(totalPages, start + visiblePageRange - 1);

    start = Math.max(1, end - visiblePageRange + 1);

    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  };

  return (
    <>
      {totalPages >= 1 ? (
        <div className="paginatoin-area text-center">
          <ul className="pagination-box">
            <li
              className={+activePage === 1 ? "disabled" : ""}
              onClick={() => {
                if (+activePage > 1) fetchPage(+activePage - 1);
              }}
            >
              <a href="#topbar" className="previous">
                <i className="pe-7s-angle-left"></i>
              </a>
            </li>
            {generatePageNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={+activePage === pageNumber ? "active" : ""}
                onClick={() => {
                  if (+activePage !== pageNumber) fetchPage(pageNumber);
                }}
              >
                <a href="#topbar">{pageNumber}</a>
              </li>
            ))}
            <li
              className={+activePage === totalPages ? "disabled" : ""}
              onClick={() => {
                if (+activePage < totalPages) fetchPage(+activePage + 1);
              }}
            >
              <a href="#topbar" className="next">
                <i className="pe-7s-angle-right"></i>
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
