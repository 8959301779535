import { useDispatch, useSelector } from "react-redux";
import { fetchProductBySlug, filterProduct } from "../../../redux/ProductSlice";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Shop/Pagination";

import UpdateProductModal from "./Update Product/Modal";
import { AiOutlinePlusCircle, AiOutlineShop } from "react-icons/ai";
import AddProductModal from "./Add Product/Modal";
import axios from "axios";
import { IconContext } from "react-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Products = () => {
  const { token } = useSelector((state) => state.user);
  const limit = 9;
  const dispatch = useDispatch();
  const { data, slugSearch, pagination } = useSelector(
    (state) => state.product
  );
  const [filters, setFilters] = useState([]);
  const [showUpdateProductModal, setShowUpdateProductModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [res, setRes] = useState({
    from: 0,
    to: 0,
  });

  const openModal = () => {
    setShowUpdateProductModal(true);
  };

  const closeUpdateProductModal = () => {
    setShowUpdateProductModal(false);
  };
  const closeAddProductModal = () => {
    setShowAddProductModal(false);
  };

  const fetchProduct = (slug) => {
    dispatch(fetchProductBySlug(slug));
    openModal();
  };

  const fetchPage = (page) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/product/filter?keyword=${
      searchKeyword || ""
    }&limit=${limit}&page=${page || 1}`;

    dispatch(
      filterProduct({
        url,
      })
    );
  };

  const deleteProduct = async (productId) => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Product...",
          success: "Product Deleted Successfully!",
          error: "Failed to Delete Product.",
        }
      );

      fetchPage(activePage);
    } catch (error) {
      toast.error("Failed to Delete Product");
    }
  };

  useEffect(() => {
    fetchPage();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/filter`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setFilters(res);
      })
      .catch((err) => {
        toast.error("Failed to Fetch Product");
      });
  }, []);

  useEffect(() => {
    const skip = pagination?.limit * (pagination?.page - 1);
    setRes({
      from: skip + 1,
      to: pagination?.fetchedProducts + skip,
    });

    setTotalPages(
      Math.ceil(pagination?.totalFilterResults / pagination?.limit)
    );
    setActivePage(pagination?.page);
  }, [pagination]);

  return (
    <>
      <UpdateProductModal
        showModal={showUpdateProductModal}
        closeModal={closeUpdateProductModal}
        product={slugSearch}
        filters={filters}
      />
      <AddProductModal
        showModal={showAddProductModal}
        closeModal={closeAddProductModal}
        filters={filters}
        reloadPage={() => {
          fetchPage(activePage);
        }}
      />

      <div className="myaccount-content">
        <div
          className="d-flex justify-content-between align-items-center mb-4"
          style={{ minHeight: "56px" }}
        >
          <div className="d-flex gap-2 align-items-center flex-wrap">
            <h5
              style={{
                border: "none",
                margin: "0",
                padding: "0",
              }}
            >
              Products
            </h5>
            <button
              title="Add New Product"
              onClick={() => {
                setShowAddProductModal(true);
              }}
            >
              <AiOutlinePlusCircle size={25} />
            </button>
          </div>
          <div className="header-search-container">
            <form
              className="header-search-box d-xl-block"
              onSubmit={(e) => {
                e.preventDefault();
                fetchPage();
              }}
            >
              <input
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder="Search Products"
                className="header-search-field"
              />
              <button type="submit" className="header-search-btn">
                <i className="pe-7s-search"></i>
              </button>
            </form>
          </div>
        </div>

        <div className="myaccount-table table-responsive text-center">
          {data?.length <= 0 ? (
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-4"
              style={{ height: "80vh" }}
            >
              <IconContext.Provider value={{ color: "#C29958" }}>
                <AiOutlineShop size={100} />
              </IconContext.Provider>
              <p>
                No Product Found. Please{" "}
                <button
                  onClick={() => {
                    setShowAddProductModal(true);
                  }}
                  style={{ color: "#C29958" }}
                >
                  Add
                </button>{" "}
                Product.
              </p>
            </div>
          ) : (
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Sr. No.</th>
                  <th>Product Name</th>
                  <th>Base Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((product, index) => {
                  return (
                    <tr key={product?._id}>
                      <td>{res.from + index}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {product?.title}
                      </td>
                      <td>{product?.basePrice}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        <Link
                          onClick={() => {
                            fetchProduct(product?.slug);
                          }}
                          className="btn btn-sqr"
                          style={{ marginRight: "10px" }}
                        >
                          Edit
                        </Link>
                        <Link
                          onClick={() => {
                            deleteProduct(product?._id);
                          }}
                          className="btn btn-sqr"
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <Pagination
          totalPages={totalPages}
          activePage={activePage}
          fetchPage={fetchPage}
        />
      </div>
    </>
  );
};

export default Products;
