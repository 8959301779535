import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = ({ text }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div className="breadcrumb-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="fa fa-home"></i>
                    </Link>
                  </li>
                  {pathnames?.map((path, index) => {
                    const routeTo = `/${pathnames
                      .slice(0, index + 1)
                      .join("/")}`;
                    const isLast = index === pathnames.length - 1;
                    return (
                      <li
                        className={`breadcrumb-item ${isLast ? "active" : ""}`}
                        key={index}
                      >
                        {isLast ? (
                          path
                        ) : (
                          <Link to={routeTo}>
                            {path.charAt(0).toUpperCase() + path.slice(1)}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
