import React, { useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Sidebar from "../components/Shop/Sidebar";
import ProductList from "../components/Shop/ProductList";
import Pagination from "../components/Shop/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { filterProduct } from "../redux/ProductSlice";
import { useSearchParams } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineFilter, AiOutlineShop } from "react-icons/ai";
import { PiSortAscending } from "react-icons/pi";
import SortModal from "../components/Shop/SortModal";
import FilterModal from "../components/Shop/FilterModal";
import axios from "axios";

const Shop = () => {
  const limit = 9;
  const [viewMode, setViewMode] = useState("grid-view");
  const { data, pagination } = useSelector((state) => state.product);
  const [sortBy, setSortBy] = useState("");
  const [res, setRes] = useState({
    from: 0,
    to: 0,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [priceRange, setPriceRange] = useState({
    min: 1,
    max: 1000,
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [priceFilter, setPriceFilter] = useState({
    min: priceRange.min,
    max: priceRange.max,
  });
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isHBasePrice, setIsHBasePrice] = useState(false);

  const fetchPage = (page) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/product/filter?keyword=${
      searchParams.get("que") || ""
    }&limit=${limit}&page=${page || 1}`;

    dispatch(
      filterProduct({
        url,
        body: {
          filterOptions: selectedFilters,
          sortBy,
          basePrice: { gte: priceFilter.min, lte: priceFilter.max },
        },
      })
    );
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/product/hBasePrice`)
      .then((response) => {
        setPriceRange((range) => {
          return { ...range, max: response.data };
        });
        setPriceFilter((filter) => {
          return { ...filter, max: response.data };
        });
        setIsHBasePrice(true);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (isHBasePrice) {
      fetchPage();
    }
  }, [priceFilter, selectedFilters, sortBy, searchParams.get("que")]);

  useEffect(() => {
    const skip = pagination?.limit * (pagination?.page - 1);
    setRes({
      from: skip + 1,
      to: pagination?.fetchedProducts + skip,
    });

    setTotalPages(
      Math.ceil(pagination?.totalFilterResults / pagination?.limit)
    );
    setActivePage(pagination?.page);
  }, [pagination]);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  return (
    <>
      <SortModal
        showModal={showSortModal}
        closeModal={() => {
          setShowSortModal(false);
        }}
        setSortBy={setSortBy}
      />
      <FilterModal
        showModal={showFilterModal}
        closeModal={() => {
          setShowFilterModal(false);
        }}
        val={{ priceRange, selectedFilters, priceFilter }}
        fun={{ setSelectedFilters, setPriceFilter }}
      />
      <Breadcrumb />
      <div className="shop-main-wrapper section-padding pt-0">
        <div className="container">
          <div className="py-4 d-flex align-items-center gap-2">
            {searchParams.get("que") && (
              <>
                <h4 className="pt-1">Showing Results for:</h4>
                <p
                  style={{
                    textTransform: "capitalize",
                    fontSize: "1.2rem",
                  }}
                >
                  {searchParams.get("que")}
                </p>
              </>
            )}
          </div>
          <div className="row">
            <Sidebar
              val={{ priceRange, selectedFilters, priceFilter }}
              fun={{ setSelectedFilters, setPriceFilter }}
              className="d-none d-lg-block"
            />
            <div
              className="d-lg-none "
              style={{
                position: "fixed",
                bottom: "0",
                background: "white",
                padding: "10px",
                zIndex: 99,
                left: 0,
              }}
            >
              <button
                className="col-6"
                style={{ borderRight: "1px solid grey" }}
                onClick={() => {
                  setShowSortModal(true);
                }}
              >
                <PiSortAscending size={25} />
                Sort
              </button>
              <button
                className="col-6"
                onClick={() => {
                  setShowFilterModal(true);
                }}
              >
                <AiOutlineFilter size={20} />
                Filter
              </button>
            </div>
            {pagination.totalFilterResults ? (
              <div id="topbar" className="col-lg-9 order-1 order-lg-2">
                <div className="shop-product-wrapper">
                  <div className="shop-top-bar">
                    <div className="row align-items-center">
                      <div className="col-lg-7 col-md-6 order-2 order-md-1">
                        <div className="top-bar-left">
                          <div className="product-view-mode">
                            <a
                              href="#"
                              className={
                                viewMode === "grid-view" ? "active" : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleViewModeChange("grid-view");
                              }}
                              data-bs-toggle="tooltip"
                              title="Grid View"
                            >
                              <i className="fa fa-th"></i>
                            </a>
                            <a
                              href="#"
                              className={
                                viewMode === "list-view" ? "active" : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleViewModeChange("list-view");
                              }}
                              data-bs-toggle="tooltip"
                              title="List View"
                            >
                              <i className="fa fa-list"></i>
                            </a>
                          </div>
                          <div className="product-amount">
                            <p>
                              Showing {res.from}-{res.to} of{" "}
                              {pagination.totalFilterResults} results
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-6 order-1 order-md-2 d-none d-md-block">
                        <div className="top-bar-right">
                          <div className="product-short" style={{}}>
                            <p
                              style={{
                                margin: "0 1rem",
                              }}
                            >
                              Sort By :
                            </p>
                            <select
                              className="nice-select"
                              name="sortby"
                              onChange={(e) => setSortBy(e.target.value)}
                            >
                              <option value="">Relevance</option>
                              <option value="title">Title (A - Z)</option>
                              <option value="-title">Title (Z - A)</option>
                              <option value="basePrice">
                                Price (Low - High)
                              </option>
                              <option value="-basePrice">
                                Price (High - Low)
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProductList products={data} viewMode={viewMode} />
                  <Pagination
                    totalPages={totalPages}
                    activePage={activePage}
                    fetchPage={fetchPage}
                  />
                </div>
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-center align-items-center gap-4 col-lg-9 order-1 order-lg-2"
                style={{ height: "80vh" }}
              >
                <IconContext.Provider value={{ color: "#C29958" }}>
                  <AiOutlineShop size={100} />
                </IconContext.Provider>
                <p>No Product Found. Please Try Again.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
