import React from "react";
import { Link } from "react-router-dom";

const CartCalculator = ({ total }) => {
  return (
    <div className="cart-calculator-wrapper">
      <div className="cart-calculate-items">
        <h6>Cart Totals</h6>
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {/* <tr>
                <td>Sub Total</td>
                <td>${total}</td>
              </tr> */}
              <tr className="total">
                <td>Total</td>
                <td className="total-amount">₹{total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Link to="/checkout" className="btn btn-sqr d-block">
        Proceed Checkout
      </Link>
    </div>
  );
};

export default CartCalculator;
