import { useEffect } from "react";
import React, { useState } from "react";

const BillingDetails = ({
  formData,
  setFormData,
  addresses,
  selectedAddress,
  setSelectedAddress,
  activeTab,
  setActiveTab,
}) => {
  const handleAddressChange = (event) => {
    const selectedAdd = event.target.value;
    setSelectedAddress(selectedAdd);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setSelectedAddress(addresses && addresses[0]?._id);
  }, [addresses]);

  return (
    <div className="checkout-billing-details-wrap">
      <h5 className="checkout-title">Billing Details</h5>
      <div className="product-review-info">
        <ul className="nav review-tab">
          <li>
            <a
              className={activeTab === "tab_one" ? `active` : ""}
              data-bs-toggle="tab"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActiveTab("tab_one");
              }}
            >
              New Address
            </a>
          </li>
          <li>
            <a
              className={activeTab === "tab_two" ? `active` : ""}
              data-bs-toggle="tab"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActiveTab("tab_two");
              }}
            >
              Your Addresses
            </a>
          </li>
        </ul>
        <div className="tab-content reviews-tab">
          <div
            className={`tab-pane fade   ${
              activeTab === "tab_one" ? "show active" : ""
            } `}
            id="tab_one"
          >
            <div className="tab-one">
              <form action="#">
                <div className="single-input-item">
                  <label htmlFor="country" className="required">
                    Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="single-input-item">
                  <label htmlFor="streetAddress" className="required mt-20">
                    Street address
                  </label>
                  <input
                    type="text"
                    id="line1"
                    name="line1"
                    placeholder="Street address Line 1"
                    value={formData.line1}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="single-input-item">
                  <input
                    type="text"
                    placeholder="Street address Line 2 (Optional)"
                    name="line2"
                    value={formData.line2}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="single-input-item">
                  <label htmlFor="city" className="required">
                    Town / City
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Town / City"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="single-input-item">
                  <label htmlFor="state">State / Division</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    placeholder="State / Division"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="single-input-item">
                  <label htmlFor="postalCode" className="required">
                    Postcode / ZIP
                  </label>
                  <input
                    type="number"
                    id="postalCode"
                    name="postalCode"
                    placeholder="Postcode / ZIP"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            className={`tab-pane fade   ${
              activeTab === "tab_two" ? "show active" : ""
            } `}
            id="tab_two"
          >
            <div className="myaccount-content">
              <h5>Billing Address</h5>
              <div className="row">
                {addresses?.map((address, index) => (
                  <div className="col-md-6 " key={index}>
                    <address className="custom-control custom-radio">
                      <input
                        type="radio"
                        id={address?._id}
                        name="address"
                        value={address?._id}
                        className="custom-control-input"
                        checked={selectedAddress === address._id}
                        onChange={handleAddressChange}
                      />

                      <label
                        className="custom-control-label"
                        htmlFor={address._id}
                      >
                        {address?.line1}
                        {address?.line2 && `, ${address.line2}`}
                        <br />
                        {address.city}, {address.state} <br />
                        {address.postalCode}, {address.country}
                      </label>
                    </address>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
