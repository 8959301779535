import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/UserSlice";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    password: "",
    repeatPassword: "",
    subscribeNewsletter: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setSignupData({ ...signupData, [name]: inputValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      signupData.password.length < 8 ||
      signupData.password !== signupData.repeatPassword
    ) {
      toast.error("Please enter a valid password");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/register`,
          {
            fullName: signupData.fullName,
            email: signupData.email,
            contact: signupData.mobile,
            password: signupData.password,
          }
        );

        if (response.data.token) {
          dispatch(
            login({
              auth: true,
              name: response.data.name,
              role: response.data.role,
              token: response.data.token,
            })
          );
          navigate("/shop");
          toast.success("Registration successful!");
        } else {
          toast.error("Failed to register. Please try again.");
        }
      } catch (error) {
        if (error.response.data.message === "User already exists") {
          toast.error("User already exists with email or mobile");
        } else {
          toast.error("Failed to register. Please try again.");
        }
      }
    }
  };

  return (
    <>
      <Breadcrumb />
      <div className="login-register-wrapper section-padding">
        <div className="container">
          <div className="member-area-from-wrap d-flex justify-content-center">
            <div className="row">
              <div className="col-lg-6">
                <div className="login-reg-form-wrap sign-up-form">
                  <h5>Sign Up</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="single-input-item">
                      <input
                        type="text"
                        name="fullName"
                        placeholder="Full Name"
                        value={signupData.fullName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="single-input-item">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your Email"
                        value={signupData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="single-input-item">
                      <input
                        type="Mobile Number"
                        name="mobile"
                        placeholder="Enter your Mobile Number"
                        value={signupData.mobile}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div
                      className="single-input-item d-flex align-items-center justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter your Password"
                        value={signupData.password}
                        onChange={handleInputChange}
                        required
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: 0,
                          padding: "0 10px",
                          color: "#c29958",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <AiOutlineEye size={25} />
                        ) : (
                          <AiOutlineEyeInvisible size={25} />
                        )}
                      </button>
                    </div>
                    <div
                      className="single-input-item d-flex align-items-center justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={showRepeatPassword ? "text" : "password"}
                        name="repeatPassword"
                        placeholder="Repeat your Password"
                        value={signupData.repeatPassword}
                        onChange={handleInputChange}
                        required
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: 0,
                          padding: "0 10px",
                          color: "#c29958",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowRepeatPassword(!showRepeatPassword);
                        }}
                      >
                        {showRepeatPassword ? (
                          <AiOutlineEye size={25} />
                        ) : (
                          <AiOutlineEyeInvisible size={25} />
                        )}
                      </button>
                    </div>
                    {/* <div className="single-input-item">
                      <div className="login-reg-form-meta">
                        <div className="remember-meta">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="subnewsletter"
                              name="subscribeNewsletter"
                              checked={signupData.subscribeNewsletter}
                              onChange={handleInputChange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="subnewsletter"
                            >
                              Subscribe Our Newsletter
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="single-input-item">
                      <button type="submit" className="btn btn-sqr">
                        Register
                      </button>
                    </div>
                    <div
                      className="single-input-item text-center"
                      style={{ fontSize: "15px" }}
                    >
                      Already have Account?{" "}
                      <Link style={{ color: "#c29958" }} to="/login">
                        Log In
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
