import { useEffect } from "react";
import ProductDetailsTabs from "./DetailTabs";
import { useState } from "react";

import { ToastContainer } from "react-toastify";

const UpdateProductModal = ({ showModal, closeModal, product, filters }) => {
  const [productName, setProductName] = useState(product?.title);

  useEffect(() => {
    setProductName(product?.title);
  }, [product]);

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="orderDetailModal"
      >
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <>
                  <h5 className="modal-title">{productName}</h5>
                </>
              </div>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            {product && (
              <ProductDetailsTabs product={product} filters={filters} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProductModal;
