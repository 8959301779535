import React from "react";
// import RelatedProducts from "../components/ProductDetail/RelatedProducts";
import Breadcrumb from "../components/Breadcrumb";
import ProductDetailsReviews from "../components/ProductDetail/ProductDetailsReviews";
import ProductDetails from "../components/ProductDetail/ProductDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchProductBySlug } from "../redux/ProductSlice";
import { Link, useParams } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsFillPatchExclamationFill } from "react-icons/bs";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const { slugSearch, status } = useSelector((state) => state.product);
  const { slug } = useParams();
  useEffect(() => {
    dispatch(fetchProductBySlug(slug));
  }, []);

  return (
    <main>
      <Breadcrumb />
      {status === "loading" && (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "80vh" }}
        >
          <img src="/assets/img/logo/Rolling-1s-200px.svg" />
        </div>
      )}
      {status === "succeeded" && (
        <div className="shop-main-wrapper section-padding pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 order-1 order-lg-2 pb-5">
                <>
                  <ProductDetails product={slugSearch} />
                  <ProductDetailsReviews
                    variants={slugSearch?.variants}
                    description={slugSearch?.description}
                    // reviews={productData.reviews}
                  />
                </>
              </div>
            </div>
          </div>
        </div>
      )}
      {status === "failed" && (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "80vh" }}
        >
          <IconContext.Provider value={{ color: "#C29958" }}>
            <BsFillPatchExclamationFill size={100} />
          </IconContext.Provider>
          <p>
            Item not Found. Please checkout another product from{" "}
            <Link to="/shop" style={{ color: "#C29958" }}>
              Shop
            </Link>
            .
          </p>
        </div>
      )}
    </main>
  );
};

export default ProductDetail;
