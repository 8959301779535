import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: false,
  name: "",
  role: "",
  token: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.auth = true;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.auth = false;
      state.name = "";
      state.role = "";
      state.token = "";
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
