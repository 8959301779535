import { useState } from "react";
import CartTable from "../../Cart/CartTable";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsFillBagHeartFill, BsFillCartXFill } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import pathToUrl from "../../PathToUrl";

const UserDetailsTabs = ({ user, cartItems, wishlistItems }) => {
  const { token } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("tab_one");

  const makeAdmin = async () => {
    try {
      await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/user/${user?._id}/change-role`,
          { role: "admin" },
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Updating Role...",
          success: "User Deleted Successfully!",
          error: "Failed to Delete User.",
        }
      );
    } catch (error) {
      toast.error("Failed to Delete User");
    }
  };

  return (
    <>
      <div className="modal-body">
        <div className="product-details-reviews pt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="product-review-info">
                <ul className="nav review-tab">
                  <li>
                    <a
                      className={activeTab === "tab_one" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_one");
                      }}
                    >
                      details
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_two" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_two");
                      }}
                    >
                      Cart
                    </a>
                  </li>
                  <li>
                    <a
                      className={activeTab === "tab_three" ? `active` : ""}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_three");
                      }}
                    >
                      Wishlist
                    </a>
                  </li>
                </ul>
                <div className="tab-content reviews-tab">
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_one" ? "show active" : ""
                    } `}
                    id="tab_one"
                  >
                    <div className="tab-one">
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "20%", fontWeight: "500" }}>
                              Full Name
                            </td>
                            <td>{user?.fullName}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "500" }}>Role</td>
                            <td>{user?.role}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "500" }}>E-mail</td>
                            <td>{user?.email}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "500" }}>Contact No.</td>
                            <td>{user?.contact}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_two" ? "show active" : ""
                    } `}
                    id="tab_two"
                  >
                    <div
                      className="tab-two"
                      style={{ overflow: "auto", maxHeight: "50vh" }}
                    >
                      {cartItems?.length > 0 ? (
                        <div className="section-bg-color">
                          <div className="row">
                            <div className="col-lg-12">
                              <CartTable
                                cartItems={cartItems}
                                adminView={true}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex flex-column justify-content-center align-items-center gap-4"
                          style={{ height: "40vh" }}
                        >
                          <IconContext.Provider value={{ color: "#C29958" }}>
                            <BsFillCartXFill size={100} />
                          </IconContext.Provider>
                          <p>Nothing in Cart.</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_three" ? "show active" : ""
                    } `}
                    id="tab_three"
                  >
                    {wishlistItems?.length > 0 ? (
                      <div className="section-bg-color">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="cart-table table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="pro-thumbnail">Thumbnail</th>
                                    <th className="pro-title">Product</th>
                                    <th className="pro-price">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {wishlistItems?.map((item) => (
                                    <tr key={item._id}>
                                      <td className="pro-thumbnail">
                                        <img
                                          className="img-fluid"
                                          src={
                                            item.imageUrls &&
                                            pathToUrl(item?.imageUrls[0])
                                          }
                                          alt={item.title}
                                        />
                                      </td>
                                      <td className="pro-title">
                                        <Link to={`/${item.slug}`}>
                                          {item.title}
                                        </Link>
                                      </td>
                                      <td className="pro-price">
                                        ${item.basePrice}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center gap-4"
                        style={{ height: "40vh" }}
                      >
                        <IconContext.Provider value={{ color: "#C29958" }}>
                          <BsFillBagHeartFill size={100} />
                        </IconContext.Provider>
                        <p>Nothing in Wishlist.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer" style={{ textTransform: "capitalize" }}>
        {user.role !== "admin" && (
          <button className="btn btn-sqr" onClick={makeAdmin}>
            Give Admin Permissions
          </button>
        )}
      </div>
    </>
  );
};

export default UserDetailsTabs;
