import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BrandLogoCarousel = ({ brandImages }) => {
  const settings = {
    speed: 1000,
    slidesToShow: 5,
    adaptiveHeight: true,
    autoplay: true,
    prevArrow: (
      <button type="button" className="slick-prev">
        <i className="pe-7s-angle-left"></i>
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        <i className="pe-7s-angle-right"></i>
      </button>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="brand-logo section-padding pt-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Slider {...settings} className="brand-logo-carousel ">
              {brandImages?.map((image, index) => (
                <div className="brand-item" key={index}>
                  <a href="#">
                    <img src={image} alt={`Brand Logo ${index + 1}`} />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandLogoCarousel;
