const PaymentMethod = () => {
  return (
    <div className="myaccount-content">
      <h5>Payment Method</h5>
      <p className="saved-message">You Can't Save Your Payment Method yet.</p>
    </div>
  );
};

export default PaymentMethod;
