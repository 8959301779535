import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addToWishlist } from "../redux/WishlistSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pathToUrl from "./PathToUrl";

const ProductItem = ({
  _id,
  slug,
  imageUrls,
  title,
  basePrice,
  discount,
  isNew,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const primaryImage = imageUrls && `${imageUrls[0]}`;
  const secondaryImage =
    imageUrls && imageUrls.length > 1 ? imageUrls[1] : primaryImage;

  const addItem = async () => {
    if (!token) return toast.error("Login to add item in wishlist");
    try {
      await toast.promise(dispatch(addToWishlist({ token, id: _id })), {
        pending: "Adding to Wishlist...",
        success: "Item added to Wishlist!",
        error: "Failed to add item to Wishlist.",
      });
    } catch (error) {
      toast.error("Failed to add item to Wishlist");
    }
  };

  return (
    <>
      <div className="product-item d-flex flex-column justify-content-center align-items-center ">
        <figure
          className="product-thumb overflow-hidden"
          style={{ maxWidth: "280px", height: "220px", width: "100%" }}
        >
          <Link to={`/${slug}`}>
            <img
              className="pri-img"
              src={pathToUrl(primaryImage)}
              alt="product"
            />
            {secondaryImage && (
              <img
                className="sec-img"
                src={pathToUrl(secondaryImage)}
                alt="product"
              />
            )}
          </Link>
          <div className="product-badge">
            {isNew && (
              <div className="product-label new">
                <span>new</span>
              </div>
            )}
            {discount && (
              <div className="product-label discount">
                <span>{discount}%</span>
              </div>
            )}
          </div>
          <div className="button-group">
            <Link
              to={"#"}
              onClick={() => addItem()}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Add to wishlist"
            >
              <i className="pe-7s-like"></i>
            </Link>
          </div>
          <div className="cart-hover">
            <Link to={`/${slug}`} className="btn btn-cart">
              View Item
            </Link>
          </div>
        </figure>
        <div className="product-caption text-center">
          <h6 className="product-name">
            <Link to={`/${slug}`}>{title}</Link>
          </h6>
          <div className="price-box">
            <span className="price-regular">₹{basePrice?.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProductListItem = ({
  _id,
  imageUrls,
  title,
  basePrice,
  discount,
  description,
  isNew,
  slug,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const primaryImage = imageUrls && imageUrls[0];
  const secondaryImage =
    imageUrls && imageUrls.length > 1 ? imageUrls[1] : primaryImage;
  const addItem = async () => {
    if (!token) return toast.error("Login to add item in wishlist");
    try {
      await toast.promise(dispatch(addToWishlist({ token, id: _id })), {
        pending: "Adding to Wishlist...",
        success: "Item added to Wishlist!",
        error: "Failed to add item to Wishlist.",
      });
    } catch (error) {
      toast.error("Failed to add item to Wishlist");
    }
  };

  return (
    <>
      <div className="product-list-item">
        <figure
          className="product-thumb overflow-hidden"
          style={{ maxWidth: "280px", height: "220px" }}
        >
          <Link>
            <img
              className="pri-img"
              src={pathToUrl(primaryImage)}
              style={{ maxWidth: "276px" }}
              alt="product"
            />
            {secondaryImage && (
              <img
                className="sec-img"
                style={{ maxWidth: "276px" }}
                src={pathToUrl(secondaryImage)}
                alt="product"
              />
            )}
          </Link>
          <div className="product-badge">
            {isNew && (
              <div className="product-label new">
                <span>new</span>
              </div>
            )}
            {discount && (
              <div className="product-label discount">
                <span>{discount}%</span>
              </div>
            )}
          </div>
          <div className="button-group">
            <Link
              onClick={() => addItem()}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Add to wishlist"
            >
              <i className="pe-7s-like"></i>
            </Link>
          </div>
          <div className="cart-hover">
            <Link to={`/${slug}`} className="btn btn-cart">
              View Item
            </Link>
          </div>
        </figure>
        <div className="product-content-list">
          <h5 className="product-name">
            <Link to={`/${slug}`}>{title}</Link>
          </h5>
          <div className="price-box">
            <span className="price-regular">₹{basePrice.toFixed(2)}</span>
            {discount && (
              <span className="price-old">
                <del>₹{(basePrice / (1 - discount / 100)).toFixed(2)}</del>
              </span>
            )}
          </div>
          <p>{description}</p>
        </div>
      </div>
    </>
  );
};

export default ProductItem;
