import React from "react";
import ProductSlider from "../ProductSlider";
import { useSelector } from "react-redux";
import { AiOutlineShop } from "react-icons/ai";
import { IconContext } from "react-icons";

const ProductSection = () => {
  const { data } = useSelector((state) => state.product);
  return (
    <section className="product-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <h2 className="title">our products</h2>
              <p className="sub-title">Add our products to the weekly lineup</p>
            </div>
          </div>
        </div>
        <div className="row">
          {data.length ? (
            <div className="col-12">
              <div className="product-container">
                <ProductSlider products={data} />
              </div>
            </div>
          ) : (
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-4 "
              style={{ height: "80%" }}
            >
              <IconContext.Provider value={{ color: "#C29958" }}>
                <AiOutlineShop size={40} />
              </IconContext.Provider>
              <p>Coming Soon...</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductSection;
