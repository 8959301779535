import React, { useState } from "react";
import Slider from "react-slick";

const Testimonial = ({ image, content, author }) => {
  return (
    <div className="testimonial-content">
      <p>{content}</p>
      <div className="ratings">
        {[...Array(5)]?.map((star, index) => (
          <span key={index}>
            <i className="fa fa-star-o"></i>
          </span>
        ))}
      </div>
      <h5 className="testimonial-author">{author}</h5>
    </div>
  );
};

const TestimonialSection = () => {
  const testimonials = [
    {
      image: "assets/img/testimonial/testimonial-1.png",
      content: "Vivamus a lobortis ipsum, vel condimentum magna...",
      author: "Lindsy Niloms",
    },
    {
      image: "assets/img/testimonial/testimonial-2.png",
      content: "Vivamus a lobortis ipsum, vel condimentum magna...",
      author: "Daisy Millan",
    },
    {
      image: "assets/img/testimonial/testimonial-3.png",
      content: "Vivamus a lobortis ipsum, vel condimentum magna...",
      author: "Anamika Lusy",
    },
    {
      image: "assets/img/testimonial/testimonial-2.png",
      content: "Vivamus a lobortis ipsum, vel condimentum magna...",
      author: "Maria Mora",
    },
  ];
  const [contentSlider, setContentSlider] = useState(null);
  const [thumbSlider, setThumbSlider] = useState(null);

  const contentSliderSettings = {
    arrows: false,
    asNavFor: thumbSlider,
  };

  const thumbSliderSettings = {
    slidesToShow: 3,
    centerMode: true,
    arrows: false,
    centerPadding: 0,
    focusOnSelect: true,
    asNavFor: contentSlider,
  };

  return (
    <section
      className="testimonial-area section-padding "
      style={{
        backgroundImage: `url(assets/img/testimonial/testimonials-bg.jpg)`,
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center">
              <h2 className="title">testimonials</h2>
              <p className="sub-title">What they say</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="testimonial-thumb-wrapper">
              <Slider
                ref={(slider) => setThumbSlider(slider)}
                {...thumbSliderSettings}
                className="testimonial-thumb-carousel"
              >
                {testimonials?.map((testimonial, index) => (
                  <div className="testimonial-thumb" key={index}>
                    <img src={testimonial.image} alt="testimonial-thumb" />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="testimonial-content-wrapper">
              <Slider
                asNavFor={thumbSlider}
                ref={(slider) => setContentSlider(slider)}
                {...contentSliderSettings}
                className="testimonial-content-carousel"
              >
                {testimonials?.map((testimonial, index) => (
                  <Testimonial key={index} {...testimonial} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
