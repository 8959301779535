import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductItem from "./ProductItem";

const ProductSlider = ({ products }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    prevArrow: (
      <button type="button" className="slick-prev">
        <i className="pe-7s-angle-left"></i>
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        <i className="pe-7s-angle-right"></i>
      </button>
    ),
    autoplay: true,
    slidesToShow: products.length > 4 ? 4 : products.length,
    slidesToScroll: 1,
    arrows: true,
    
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: products.length > 3 ? 4 : products.length,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: products.length > 2 ? 4 : products.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: products.length > 1 ? 4 : products.length,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="slick-arrow-style">
      {products?.map((product, index) => (
        <ProductItem {...product} key={index} />
      ))}
    </Slider>
  );
};

export default ProductSlider;
